import React, { useState, useEffect } from "react";
import data from '../../Api'
import './StudentTable.css'
import Axios from 'axios'
import moment from "moment";
import { store } from "react-notifications-component";
import Loader from '../Loaders/Loader.js';
import ReactPaginate from 'react-paginate';
const {
    generateVC,
    VerifiableCrenticalVC
} = data
const StudentTable = (props) => {

    const [studentList, setStudentList] = useState([])
    const [vc, setVc] = useState('')
    const [verifyVC, setVerifyVC] = useState("")
    const [vCData, setVCData] = useState('')
    const [verifiableCredential, setVerifiableCredential] = useState("")
    const [totalCount, setTotalCount] = useState('0')
    const [search, setSearch] = useState("");
    const [page, setPage] = useState("1")
    const [limit, setLimit] = useState("10")
    const [dataPerPage,setDataPerPage] = useState(0)
    
    useEffect(() => {

        getStudentList();

    }, [])



    const getStudentList = () => {
        //   Axios.get("/getStudentList")
        Axios.get(`/getstudentListAdmin?search=${search}&page=${page}&limit=${limit}`)
            .then((response) => {
             let pageData = Math.ceil(response.data.length / limit)
                console.log("student List", response)
                setStudentList(response.data)
                setTotalCount(response.data.length)
                setDataPerPage(pageData)
            })
            .catch((error) => {

            })
    }

    const generateVCData = (data) => {


        if (data) {
            generateVC()
           
                .then((data) => {
                    console.log("vc data", data.data)
                    console.log(" vc  data 46", data.data.verifiableCredential)
                    setVerifiableCredential(data.data.verifiableCredential)
                  
                    setVc(data.message)

                    if (data.message) {
                        let saveVc = {
                            userSignature: data.data.userSignature,
                            verifiableCredential: {
                                //  context: data.data.verifiableCredential.context,
                                context: "https://www.w3.org/2018/credentials/v1",
                                biometrics: {
                                    face: data.data.verifiableCredential.biometrics.face,
                                    finger: data.data.verifiableCredential.biometrics.finger,
                                    iris: data.data.verifiableCredential.biometrics.iris,
                                },
                                credentialSchema: {
                                    id: data.data.verifiableCredential.credentialSchema.id,
                                    type: data.data.verifiableCredential.credentialSchema.type
                                },
                                credentialSubject: [{
                                    id: data.data.verifiableCredential.credentialSubject[0].id,
                                    earthId: data.data.verifiableCredential.credentialSubject[0].earthId,
                                    studentTranscript: {
                                        completion_date: data.data.verifiableCredential.credentialSubject[0].studentTranscript.completion_date,
                                        createdAt: data.data.verifiableCredential.credentialSubject[0].studentTranscript.createdAt,
                                        expiry_date: data.data.verifiableCredential.credentialSubject[0].studentTranscript.expiry_date,
                                        grade_overall: data.data.verifiableCredential.credentialSubject[0].studentTranscript.grade_overall,
                                        issuance_date: data.data.verifiableCredential.credentialSubject[0].studentTranscript.issuance_date,
                                        modules: data.data.verifiableCredential.credentialSubject[0].studentTranscript.modules,
                                        start_date: data.data.verifiableCredential.credentialSubject[0].studentTranscript.start_date,
                                        status: data.data.verifiableCredential.credentialSubject[0].studentTranscript.status,
                                        student_name: data.data.verifiableCredential.credentialSubject[0].studentTranscript.student_name,
                                        student_number: data.data.verifiableCredential.credentialSubject[0].studentTranscript.student_number,
                                        student_registration_number: data.data.verifiableCredential.credentialSubject[0].studentTranscript.student_registration_number,
                                        course_name: data.data.verifiableCredential.credentialSubject[0].studentTranscript.course_name,
                                        subjects: data.data.verifiableCredential.credentialSubject[0].studentTranscript.subjects


                                    }
                                }],
                                expirationDate: data.data.verifiableCredential.expirationDate,
                                id: data.data.verifiableCredential.id,
                                issuanceDate: data.data.verifiableCredential.issuanceDate,
                                issuer: data.data.verifiableCredential.issuer,
                                proof: {
                                    created: data.data.verifiableCredential.proof.created,
                                    creator: data.data.verifiableCredential.proof.creator,
                                    jws: data.data.verifiableCredential.proof.jws,
                                    proofPurpose: data.data.verifiableCredential.proof.proofPurpose,
                                    type: data.data.verifiableCredential.proof.type,
                                    vcVerificationMethod: data.data.verifiableCredential.proof.vcVerificationMethod
                                },
                                type: data.data.verifiableCredential.type,
                                version: data.data.verifiableCredential.version,
                                message: data.message,
                                success: data.success
                            }



                        }
                        console.log("save VC data.data", saveVc)
                        Axios.post("/savevc", saveVc)
                            .then((data) => {
                                console.log("save vc response for our db", data)


                            })
                            .catch((err) => {
                                console.log(err)
                            })

                    }



                    if (data.message == "Verifiable Credential created and submited successfully") {
                        sessionStorage.setItem('VCData', JSON.stringify(data));


                        store.addNotification({

                            title: "Success!",

                            message: data.message,

                            type: "success",

                            insert: "top",

                            container: "top-right",

                            animationIn: ["animated", "fadeIn"],

                            animationOut: ["animated", "fadeOut"],

                            dismiss: {

                                duration: 3000,

                                onScreen: true,

                            },

                        });

                    }

                })
                .catch((err) => {
                    alert(err);

                })
        }



    }
    const verifiVC = async (data) => {
        let profileDetails = JSON.parse(sessionStorage.getItem('profileData'))
        console.log("profileDetails", profileDetails)
        console.log("170", verifiableCredential)

      
        return new Promise((resolve, reject) => {
            try {
                var config = {
                    method: "post",
                    url: 'https://ssi-test.myearth.id/api/verifier/verify?verifyParams=&zpkValidation=false',
                    headers: {
                        "Content-Type": "application/json",
                        "X-API-KEY": "01a41742-aa8e-4dd6-8c71-d577ac7d463c",
                        'publicKey': profileDetails.publicKey,

                    },
                    data: {
                        "credentials": verifiableCredential

                    }



                };

                Axios(config, { crossdomain: true })
                    .then((res) => {
                        console.log("verifi vc", res)
                        console.log("final vc response", res.data.message)
                      

                        setVerifyVC(res.data.message)
                        if (data.message == "The User is valid. Issuer DID is verified and found to be valid. The credential is verified and found to be valid") {
                            store.addNotification({

                                title: "Success!",

                                message: res.data.message,

                                type: "success",

                                insert: "top",

                                container: "top-right",

                                animationIn: ["animated", "fadeIn"],

                                animationOut: ["animated", "fadeOut"],

                                dismiss: {

                                    duration: 3000,

                                    onScreen: true,

                                },

                            });
                        }
                        return resolve(res.data);




                    })
                    .catch((e) => {
                        console.log("This is e ", e);
                        return reject("unable to verfi VC");
                    });
            }
            catch (e) {
                console.log("Error in qr Code ", e);
                return reject(e);
            }
        });

    }
    const sendMail = (data) => {
        let emailData = JSON.parse(sessionStorage.getItem('profileData'))
        console.log("257", emailData)
        let emailValue = {
            email: emailData.userEmail,
            studentNo: emailData.userName

        }

        console.log("email values", emailValue)
        Axios.post("/sentmail", emailValue)
            .then((response) => {
                console.log("response email", response)
                if (response.data.message == "check your mail box") {
                    store.addNotification({

                        title: "Success!",

                        message: "Qr Code is Sent on Your Registred Mail",

                        type: "success",

                        insert: "top",

                        container: "top-right",

                        animationIn: ["animated", "fadeIn"],

                        animationOut: ["animated", "fadeOut"],

                        dismiss: {

                            duration: 3000,

                            onScreen: true,

                        },

                    });

                } else {
                    store.addNotification({

                        title: "Error!",

                        message: "Mail not sent",

                        type: "danger",

                        insert: "top",

                        container: "top-right",

                        animationIn: ["animated", "fadeIn"],

                        animationOut: ["animated", "fadeOut"],

                        dismiss: {

                            duration: 3000,

                            onScreen: true,

                        },

                    });

                }


            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handlePageClick = (event) => {
        setPage(event.event.selected + 1)
    };
    useEffect(() => {
        getStudentList()
    }, [page])
    
    useEffect(() => {
        getStudentList()
    }, [search])

    return (
        <>
            <div class="middleSection-wrapper" >
                <div class="dashboardOuter">
                    <div class="rightSection">
                        <div class="container">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="student_Title" >
                                    <h1> Total Student : {totalCount}</h1>


                                </div>
                                <div className="">
                                    <div class="input-group">
                                        <div class="form-outline">
                                            <input type="search" placeholder="Search" id="form1" class="form-control searchBar" name="search" value={search} onChange={e=>setSearch(e.target.value)} />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="tableWrapper">

                                <table class="table table-bordered">

                                    <thead>
                                        <tr>
                                            <th scope="col">Serial Number</th>
                                            <th scope="col">Student Name</th>
                                            <th scope="col">Student Number</th>
                                            {/* <th scope="col">Generate VC</th>
                                            <th scope="col">Verify VC</th>
                                            <th scope="col">Email QR</th> */}




                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            studentList && studentList.length > 0 ?
                                                studentList.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{data.student_name}</td>
                                                            <td>{data.student_number}</td>


                                                            {/* <td>


                                                                <>
                                                                    {


                                                                        data.student_number ?
                                                                            <>
                                                                                <button className="generateVCAdmin" onClick={() => generateVCData(data.earthId)}>Generate VC</button>



                                                                            </>
                                                                            :

                                                                            <button className="generateVCAdmin" style={{ background: '#ccc' }} disabled >Generate VC</button>



                                                                    }
                                                                </>

                                                            </td> */}
                                                            {/* <td>

                                                                {
                                                                    data.student_name ? <button onClick={() => verifiVC(data.student_number)} className="generateVCAdmin" >Verify VC</button> : null


                                                                }
                                                            </td> */}



                                                            {/* <td>

                                                                {
                                                                    data.student_name ? <button className="generateVCAdmin" onClick={() => sendMail(data.student_number)}>Email Qr Code</button> : null


                                                                }

                                                            </td>

 */}

                                                        </tr>

                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td style={{ textAlign: 'center' }} colSpan={8}>Data not available</td>
                                                </tr>

                                        }

                                    </tbody>
                                </table>

                            </div>
                            <div className="list-pagination">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={dataPerPage}
                                    previousLabel="previous"
                                    renderOnZeroPageCount={null}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                />
                            </div>

                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}
export default StudentTable;
