import React, { Component } from "react";
import './VerifiVC.css'
import data from '../Api'
import { store } from "react-notifications-component";
import QRCode from 'qrcode.react';

const {
    VerifiableCrenticalVC
} = data
export default class VerifiVC extends Component {
    constructor(props) {
        super(props)
        this.state = {
           

        }
    }
    verifiVC() {
        console.log('abc')
        VerifiableCrenticalVC()
            .then((data) => {
                console.log("final verifi data", data)
                if (data.message == "The User is valid. Issuer DID is verified and found to be valid. The credential is verified and found to be valid") {
                    store.addNotification({

                        title: "Success!",

                        message: data.message,

                        type: "success",

                        insert: "top",

                        container: "top-right",

                        animationIn: ["animated", "fadeIn"],

                        animationOut: ["animated", "fadeOut"],

                        dismiss: {

                            duration: 3000,

                            onScreen: true,

                        },

                    });
                }

            })
            .catch((err) => {
                alert(err);
            })
    }
    render() {
        return (
            <>
                <div className="container">
                    <div className=' col-lg-9 imagewrapper'>
                        <img src="/Logo1.png" alt="logo" />
                    </div>
                    <div className="col-lg-9" style={{ margin: '0px 0px 0px 134px', display: 'flex', justifyContent: 'space-between',width: "38%" }}>
                        <button type="button" class="btn btn-primary login_button" data-toggle="modal" data-target="#exampleModal">
                           Qr code
                        </button>


                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                       <h5 class="modal-title" id="exampleModalLabel">Waiting for authorization</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body"style={{ textAlign:'center'}}>
                                       
                                         <QRCode value="abc" 
                                          style={{
                                            height: "190px",
                                            width: "190px",
                                           
                                          }}
                                         />
                   
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary login_button" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="login_button" onClick={this.verifiVC.bind(this)}>Verify VC</button>
                    </div>

                </div>
            </>
        )
    }

}