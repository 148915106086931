import React from 'react'
import Header from "../Components/CommonComponents/Header/Header";
import Sidebar from '../Components/CommonComponents/Sidebar/Sidebar';
import DashboardMiddle from '../DashboardMiddle/dashboard';
import Footer from '../Components/CommonComponents/Footer/Footer'

const Dashboard = () =>{
    return(
        <div className=''>
        <Header />
        <Sidebar />
        <DashboardMiddle/>
         <Footer /> 
        
        </div>
    )
}
export default Dashboard;