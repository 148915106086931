import React, { useState, useEffect, useCallback } from "react";
import data from '../Api'
import Axios from 'axios'
import moment from "moment";
import { store } from "react-notifications-component";
import ReactPaginate from 'react-paginate';
import Loader from '../Components/Loaders/Loader'
import ContentLoader from '../Components/Loaders/Contentloader'
const {
    generateVC,
    VerifiableCrenticalVC,
    generateKeysAdmin
} = data
const VerifyVc = (props) => {

    const [studentList, setStudentList] = useState([])
    const [totalCount, setTotalCount] = useState('0')
    const [search, setSearch] = useState("");
    const [page, setPage] = useState("1")
    const [limit, setLimit] = useState("10")
    const [dataPerPage, setDataPerPage] = useState(0)
    const [loading5, setLoading5] = useState(false)
    const [verifyVCMsg, setVerifyVCMsg] = useState('')
    const [verifyLoader, setVerifyLoader] = useState('')

    useEffect(() => {

        getStudentList();

    }, [])

    const showSuccessNotification = useCallback(() => {
        store.addNotification({
            title: "Success!",
            message: "The User is valid. Issuer DID is verified and found to be valid. The credential is verified and found to be valid",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
    }, []);

    useEffect(() => {
        if (verifyVCMsg) {
            showSuccessNotification();
        }
    }, [verifyVCMsg, showSuccessNotification]);



    const getStudentList = () => {
        setVerifyLoader(true)
        Axios.get(`/getVerifyVCAdmin?search=${search}&page=${page}&limit=${limit}`)
            .then((response) => {
                setVerifyLoader(false)
                //  console.log("response verfi", response)
                let pageData = Math.ceil(response.data.total / limit)
                setStudentList(response.data.students)
                setTotalCount(response.data.total)
                setDataPerPage(pageData)
            })
            .catch((error) => {

            })
    }



    const verifiVC = async (data) => {
        //  console.log("72", data, data.student_number)
        return new Promise((resolve, reject) => {
            try {
                var config = {
                    method: "post",
                    url: 'https://ssi-test.myearth.id/api/verifier/verify?verifyParams=&zpkValidation=false',
                    headers: {
                        "Content-Type": "application/json",
                        "X-API-KEY": "01a41742-aa8e-4dd6-8c71-d577ac7d463c",
                        'publicKey': data.verifiableCredential[0].credentialSubject[0].studentTranscript.publicKey,

                    },
                    data: {
                        "credentials": data.verifiableCredential[0]

                    }
                };
                setLoading5({ [data._id]: true })
                Axios(config, { crossdomain: true })
                    .then((res) => {
                        setVerifyVCMsg(res.data.message)
                        setLoading5({ [data._id]: false })
                        resolve(res.data);

                    })
                    .catch((e) => {
                        setLoading5({ [data._id]: false })
                        console.log("This is e 101 ", e);
                        store.addNotification({
                            title: "Error!",
                            message: "Unable to verfi VC",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                        return reject("unable to verfi VC");
                    });
            }
            catch (e) {
                console.log("Error in qr Code ", e);
                return reject(e);
            }
        });

    }


    const handlePageClick = (event) => {
        setPage(event.selected + 1)
    };
    useEffect(() => {

        getStudentList()
    }, [page])

    useEffect(() => {
        getStudentList()
    }, [search])








    return (
        <>
            <div class="middleSection-wrapper">
                <div class="dashboardOuter">
                    <div class="rightSection">
                        <div class="container">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="student_Title" >
                                    <h1> Total Student : {totalCount}</h1>


                                </div>
                                <div className="">
                                    <div class="input-group">
                                        <div class="form-outline">
                                            <input type="search" placeholder="Search" id="form1" class="form-control searchBar" name="search" value={search} onChange={e => setSearch(e.target.value)} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {
                                verifyLoader == true ? <ContentLoader /> :

                                    <div class="tableWrapper">

                                        <table class="table table-bordered">

                                            <thead>
                                                <tr>
                                                    <th scope="col">S.No.</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Registration No.</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Phone No.</th>
                                                    <th scope="col">Verify Vc</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    studentList && studentList.length > 0 ?
                                                        studentList.map((data, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{data.verifiableCredential[0].credentialSubject[0].studentTranscript.student_name}</td>
                                                                    <td>{data.student_number}</td>


                                                                    <td>
                                                                        {data.verifiableCredential[0].credentialSubject[0].studentTranscript.email_id}

                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            data.verifiableCredential[0].credentialSubject[0].studentTranscript.phone_number
                                                                        }


                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            data.userSignature ?

                                                                                // <button className="generateVCAdmin" onClick={() => verifiVC(data)}>Verify Vc</button>

                                                                                <>
                                                                                    {
                                                                                        loading5[data._id] === true ? <Loader /> :

                                                                                            <button className="generateVCAdmin" onClick={() => verifiVC(data)}>Verify Vc</button>

                                                                                    }


                                                                                </>
                                                                                :

                                                                                null




                                                                        }


                                                                    </td>






                                                                </tr>

                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td style={{ textAlign: 'center' }} colSpan={8}>Data not available</td>
                                                        </tr>

                                                }

                                            </tbody>
                                        </table>

                                    </div>
                            }
                            <div className="list-pagination">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    pageCount={dataPerPage}
                                    previousLabel="previous"
                                    renderOnZeroPageCount={null}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                />
                            </div>

                        </div>


                    </div>
                </div>
            </div >


        </>
    )
}
export default VerifyVc;
