import React from 'react';
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import StudentData from './Student.js';
import StudentPdf from '../../Components/modal/StudentPdf1.js'
import data from '../../Api.js'
import moment from 'moment'
import { store } from "react-notifications-component";
import Axios from 'axios'
import QRCode from 'qrcode.react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './pdfWrapper.css'


const {
  generateVC,
  VerifiableCrenticalVC
} = data
const MySwal = withReactContent(Swal);
function PdfToHtml() {
  const [studentData, setStudentData] = useState([])
  const [subjects, setSubjects] = useState([])
  const [courseName, setCourseName] = useState([])
  const [courseCount, setCourseCount] = useState([])
  const [verifyVC, setVerifyVC] = useState("")
  const [studentNumber, setStudentNumber] = useState('')
  const [digitalData, setDigitalData] = useState("")
  const [userSignature, setUserSignature] = useState("")


  useEffect(() => {
    let student_number = JSON.parse(sessionStorage.getItem('profileData'))
    setStudentNumber(student_number.userName)
    if (localStorage.getItem("studentData"))
      var student = JSON.parse(localStorage.getItem("studentData"))
    setStudentData(student)
    setSubjects(student.subjects)
  //  setCourseName(student.course_name[0].name)
    setCourseName(student.course_name)
    let courseLength = student.course_name.length;
    if (courseLength == 2) {
      setCourseCount(courseLength)
    }

  }, [])




  const logOut = () => {
    sessionStorage.removeItem('profileData')
    window.location.href = "/"
  }


  const DigitalData = () => {
    Axios.get(`https://api.icca.ts.flexsin.org/getOneVC?studentNo=${studentNumber}`)
      .then((response) => {
        console.log("get qr code response", response.data)

        const MySwal = withReactContent(Swal);

        MySwal.fire({
          title: 'Digital Certificate(VC)',
          html: <pre>{JSON.stringify(response.data, null, 2)}</pre>,
          confirmButtonText: 'Verify',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Close',
          customClass: {
            content: 'certificate-swal',
            confirmButton: 'swal-button',
            cancelButton: 'swal-button swal-button--cancel',
          },
          width: '1000px',
          didOpen: () => {
            const verifyButton = MySwal.getConfirmButton();
            verifyButton.addEventListener('click', () => {
              const loadingSwal = MySwal.mixin({
                title: 'Loading',
                text: 'Performing verification...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                onOpen: () => {
                  loadingSwal.showLoading();
                  verifyVC()
                    .then(() => {
                      // Display success message upon successful verification
                      loadingSwal.close();
                      MySwal.fire({
                        title: 'Verification Successful',
                        text: '',
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                        customClass: {
                          confirmButton: 'swal-button',
                        },
                      });
                    })
                    .catch((error) => {
                      // Handle error case and display error message
                      loadingSwal.close();
                      MySwal.fire({
                        title: 'Verification Error',
                        text: 'An error occurred during verification.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        showCancelButton: false,
                        customClass: {
                          confirmButton: 'swal-button',
                        },
                      });
                    });
                },
              });

              loadingSwal.fire();
            });
          },
        });
        setDigitalData(JSON.stringify(response.data))
        let signatureData = response.data.userSignature.slice(0, 35)
        setUserSignature(signatureData)

      })
      .catch((error) => {
        console.log("error")
      })


  }

  return (
    <>
      <div className="container-fluid">
        <div className='container'>
          <div className='pdfWrapper'>
            <div className=' row header_logoPdf'>
              <img src="/Logo1.png" alt="logo" style={{ width: '40%' }} />
            </div>
            <div className='col-lg-12 ' style={{ display: 'flex' }}>
              <div className=' col-lg-5 academicTxpPdf'>
                <span className='academicTxt'>Academic Transcript</span>

              </div>
              <div className='col-lg-7 rightsectionpdf'>
                <div style={{ display: 'flex' }}>
                  <StudentPdf studentData={studentData} subjects={subjects} courseName={courseName} />

                  <>
                    <button type="button" class="btn btn-primary " style={{ width: '140px', height: '40px', marginLeft: '10px', borderRadius: '25px', fontSize: '18px', fontWeight: '600', backgroundColor: "#007bff", borderColor: "#007bff", color: '#fff' }} data-toggle="modal" data-target="#exampleModalqr">
                      Qr code
                    </button>


                    <div class="modal fade" id="exampleModalqr" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelqr" aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabelqr">Scan through Mobile app</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body" style={{ textAlign: 'center' }}>

                            <QRCode
                              value={`https://api.icca.ts.flexsin.org/getOneVC?studentNo=${studentNumber}`}

                              style={{
                                height: "190px",
                                width: "190px",

                              }}
                            />

                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary login_button" data-dismiss="modal">Close</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>

                  <button type="submit" onClick={DigitalData} style={{ width: '140px', height: '40px', marginLeft: '10px', borderRadius: '25px', fontSize: '18px', fontWeight: '600', backgroundColor: "#007bff", borderColor: "#007bff", color: '#fff', cursor: 'pointer' }} >Digital VC</button>

                  <button type="submit" onClick={logOut} style={{ width: '140px', height: '40px', marginLeft: '10px', borderRadius: '25px', fontSize: '18px', fontWeight: '600', backgroundColor: "#007bff", borderColor: "#007bff", color: '#fff' }} >Log Out </button>

                </div>

              </div>
            </div>
            <div className='col-lg-12'>
              <div className=' col-lg-12 hospital'>
                <span className='academicTxt2'>{courseName}</span>
              </div>
              <div className='col-lg-12 studentInfo'>
                <ul>
                  <li style={{ fontWeight: '600' }}><span style={{ fontWeight: '400', width: '200px', display: 'inline-block', fontSize: '22px' }}>Student No</span> : &nbsp; {studentData.student_number}</li>
                  <li style={{ fontWeight: '600' }}><span style={{ fontWeight: '400', width: '200px', display: 'inline-block', fontSize: '22px' }}>Student Name</span> :&nbsp; {studentData.student_name}</li>
                  <li style={{ fontWeight: '600' }}><span style={{ fontWeight: '400', width: '200px', display: 'inline-block', fontSize: "22px" }}>Date of issues</span> :&nbsp; {moment(studentData.issuance_date).format('DD-MM-YYYY')}</li>



                </ul>
              </div>
              <div className='col-lg-12 tableWrapperpdf'>

                <table style={{ width: "100%" }}>
                  <tr>
                    <th className='customcss'>Sr No.</th>
                    <th className='customcss' width="40%">Subject.</th>
                    <th className='customcss'>Marks%</th>
                    <th className='customcss'>Overall%</th>
                    <th className='customcss'>Grade</th>
                    <th className='customcss'>Result</th>
                  </tr>
                  <tbody>

                    <tr >
                      <td className='operations'> </td>
                      <td className='operations'> Introduction to Hospiltality Operations</td>
                      <td className='operations'></td>
                      <td className='operations dataValue'>75</td>
                      <td className='operations dataValue'>M</td>
                      <td className='operations dataValue'>C</td>
                    </tr>

                    {
                      subjects && subjects.length > 0 ?
                        subjects.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td className='operations2'>{index + 1}</td>
                              <td className='operations2' style={{ fontSize: '15px' }}>{data.name}</td>
                              <td className='operations2 dataValue'>{data.mark}</td>
                              <td className='operations2'></td>
                              <td className='operations2 dataValue'>{data.grade}</td>
                              <td className='operations2'></td>
                            </tr>


                          )

                        })
                        :
                        null

                    }
                    <tr>
                      <td className='operations2'>6</td>
                      <td className='operations2'>Understand Own Rates in Self Development</td>
                      <td className='operations2 dataValue'>85</td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                    </tr>
                    <tr >
                      <td className='operations'> </td>
                      <td className='operations'> Introduction to Kitchen Operations</td>
                      <td className='operations'></td>
                      <td className='operations dataValue'>66</td>
                      <td className='operations dataValue'>P</td>
                      <td className='operations dataValue'>C</td>
                    </tr>
                    <tr>
                      <td className='operations2'>7</td>
                      <td className='operations2'>Food Safety and HACCP in Catring</td>
                      <td className='operations2 dataValue'>70</td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                    </tr>
                    <tr>
                      <td className='operations2'>8</td>
                      <td className='operations2'>Presentation of Food and Assembling of Buffets</td>
                      <td className='operations2 dataValue'>67</td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                    </tr>
                    <tr>
                      <td className='operations2'>9</td>
                      <td className='operations2'>Menu Planning Costing and Catering Controls</td>
                      <td className='operations2 dataValue'>60</td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                    </tr>
                    <tr >
                      <td className='operations'> </td>
                      <td className='operations'>Introduction to Cookery </td>
                      <td className='operations'></td>
                      <td className='operations dataValue'>67</td>
                      <td className='operations dataValue'>P</td>
                      <td className='operations dataValue'>C</td>
                    </tr>
                    <tr>
                      <td className='operations2'>10</td>
                      <td className='operations2'>Use Basic Methods of Cookery</td>
                      <td className='operations2 dataValue'>65</td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                    </tr>
                    <tr>
                      <td className='operations2'>11</td>
                      <td className='operations2'>Organize and Prepare Food</td>
                      <td className='operations2 dataValue'>65</td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                    </tr>
                    <tr>
                      <td className='operations2'>12</td>
                      <td className='operations2'>Prepare Foods to Meet Dietary and Cultural Needs</td>
                      <td className='operations2 dataValue'>70</td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                    </tr>
                    <tr >
                      <td className='operations'> </td>
                      <td className='operations'> Patisserie Major </td>
                      <td className='operations'></td>
                      <td className='operations dataValue '>90</td>
                      <td className='operations dataValue'>D</td>
                      <td className='operations dataValue'>C</td>
                    </tr>
                    <tr>
                      <td className='operations2'>13</td>
                      <td className='operations2'>Prepare Pastry, Cakes and Yeast Goods</td>
                      <td className='operations2 dataValue'>91</td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                    </tr>
                    <tr>
                      <td className='operations2'>14</td>
                      <td className='operations2'>Prepare Hot and Cold Desserts</td>
                      <td className='operations2 dataValue'>80</td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                      <td className='operations2'></td>
                    </tr>




                  </tbody>

                </table>

              </div>


              <div className=' row col-lg-12 admistratorpdf'>

                <div className='col-lg-8'>
                  <span>Principle Administrator</span>

                </div>
                <div className='col-lg-4' style={{ textAlign: 'right' }}>
                  <span>see overall for rules to award of grade</span>

                </div>
              </div>
              <div className=' row col-lg-12 Footerdatapdf'>

                <div className='row col-lg-5 footerlogoLeftpdf'>
                  <img src="/Logo1.png" alt="logo" />


                </div>
                <div className='col-lg-7 footerlogoRightpdf'>
                  <div className="" style={{ float: 'right' }}>
                    <span style={{ fontSize: '12px' }}>Block 8</span>&nbsp;<strong className='boderright'></strong>
                    <span style={{ fontSize: '12px' }}>First Floor</span>&nbsp;<strong className='boderright'></strong>
                    <span style={{ fontSize: '12px' }}>Dubai Acknowledge Park</span>&nbsp;<strong className='boderright'></strong>
                    <span style={{ fontSize: '12px' }}>PD Box 500816</span>&nbsp;<strong className='boderright'></strong>
                    <span style={{ fontSize: '12px' }}>Dubai UAE</span>
                  </div>
                  <div className='contact' style={{
                    float
                      : 'right'
                  }}>
                    Tel +97144578811 <strong className='boderright'></strong> <span style={{ fontSize: '12px' }}>Fax +97144578822</span>
                  </div>
                  <div className='email' style={{
                    float
                      : 'right'
                  }}>
                    Email : info@iccadubai.ae
                  </div>
                  <div className='Uae' style={{
                    float
                      : 'right'
                  }}>
                    UAE Toll Free 800 CULINARY <strong className='boderright'></strong> <span style={{ fontSize: '12px' }}>www.iccadubai.ae</span>
                  </div>






                </div>





              </div>



            </div>
            <div class=" col-lg-12 footerTable">
              <div className='footerTable1'>
                <table>

                  <tbody>
                    <tr>
                      <td width='40%'>
                        <table>
                          <thead>
                            <tr>
                              <th className="footerText" colSpan="3">Mark range to grade</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className='footerTexttd'>100%-90%</td>
                              <td className='D'>D</td>
                              <td>Distinction</td>
                            </tr>
                            <tr>
                              <td className='footerTexttd'>89%-70%</td>
                              <td className='D'>M</td>
                              <td>Merit</td>
                            </tr>
                            <tr>
                              <td className='footerTexttd'>69%-60%</td>
                              <td className='D'>P</td>
                              <td>Pass</td>
                            </tr>
                            <tr>
                              <td className='footerTexttd'>59%-0%</td>
                              <td className='D'>F</td>
                              <td>Fail</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td width="20%">
                        <table>
                          <thead>
                            <tr>
                              <th ></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td ></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td width='40%'>
                        <table>
                          <thead>
                            <tr>
                              <th style={{ textAlign: 'start' }} colSpan="2">Result</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>C</td>
                              <td className='D'>Completed</td>

                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <td>NYC</td>
                              <td className='D'>Not yet Completed</td>

                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <td>EXMP</td>
                              <td className='D'>Example</td>

                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <td>C</td>
                              <td className='D'>Completed</td>

                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>

                </table>

              </div>

            </div>



          </div>

        </div>







      </div>
      {
        courseCount == 2 ?
          <StudentData />
          :
          null
      }


    </>












  );
}

export default PdfToHtml;
