import React from 'react';

import ReactDOM from 'react-dom';

import './index.css';

import App from './App';

//import * as serviceWorker from './serviceWorker';

import ReactNotification from 'react-notifications-component';

import 'react-notifications-component/dist/theme.css';




ReactDOM.render(

  <div className="app-container">

    <ReactNotification />

    <App />

  </div>, document.getElementById('root'));