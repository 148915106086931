import React  from 'react';
import CertificatePdf from '../modal/CertificatePdf';
import './Ceritificate.css'
const Certificate = () => {
    return (
        <>
            <div className="certificateWrapper">
                <div className='header_logo'>
                    <img src='/Logo1.png'></img>

                </div>
                <div className='header_Wrapper'>

                    <span className='header_text'>Diploma in Hospiltality - Commerical Cookery (Patisserie)</span><br />
                    <span className='guided_learning'>Guided Learning - 620 hours</span>
                    <div style={{ float: 'right' }}>
                      <CertificatePdf /> 
                    </div>


                </div>
                <div className='certificate_award'>
                    <span className='cerificate_text'>
                        Certificate awarded to
                    </span><br />
                    <strong className='ajayani_text'>Ajani Vinayangi Perera Ganegoda Arachchige</strong>

                </div>
                <div className='attend'>
                    <span className='W_attend'>Who attended</span><br />
                    <strong className='I_center'>International Center for Culinary Arts,Dubai</strong><br />
                    <span className='C_number'>Center No - 806194</span>

                </div>
                <div className='S_completed'>
                    <strong className='f_modules'>And has Sucessfully Completed the Following Modules</strong>

                </div>
                <div className='list_Data'>
                    <div className='list_tableLeft'>
                        <ul>
                            <li style={{ fontSize: '18px', display: 'flex', justifyContent: 'space-between' }}><span>Introduction to Hospiltality Operations </span> Pass</li>
                            <li style={{ fontSize: '18px', display: 'flex', justifyContent: 'space-between' }}><span>Introduction to Kitchan Operations </span> Pass</li>
                            <li style={{ fontSize: '18px', display: 'flex', justifyContent: 'space-between' }}><span>Introduction to Cookery Operations</span> Pass</li>
                            <li style={{ fontSize: '18px', display: 'flex', justifyContent: 'space-between' }}><span>Patisserie Major </span> Pass</li>
                            <li style={{ fontSize: '18px', display: 'flex', justifyContent: 'space-between' }}><span>Professional Workspace Standards </span> Pass</li>

                        </ul>
                    </div>



                </div>
                <div className='c_signSection'>
                    <ul>
                    <li style={{ fontSize: '18px', display: 'flex', justifyContent: 'space-between' }}><span>Awarded on 19 may 2023</span>ICCA/ICTP/2022/20345</li>


                    </ul>


                </div>
                <div className='c_sign'>
                    <small style={{fontSize:'17px'}}>
                        Director

                    </small><br/>
                    <span style={{fontSize:'14px'}}>International Center for  Culinary Arts FZ LLC</span>

                </div>
                <div className='c_footerImg'>
                   
                     
                            <img  style={{width:'350px'}}src="Logo1.png"></img>
                       
                       
                        
                            <img style={{width:'350px',float:'right'}} src="Logo1.png"></img>
                       
                  
                </div>

            </div>
        </>
    )
}
export default Certificate;