
import React, { Component } from "react";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import "./App.css";

import Layout from "./Components/RegisterVender/Layout";
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

class App extends Component {
  render() {

    return (

      <Layout />

    );

  }

}




export default App;
