import React, { useState, useEffect } from "react";
import data from '../Api'
import Axios from 'axios'
import moment from "moment";
import { store } from "react-notifications-component";
import ReactPaginate from 'react-paginate';
import Loader from '../Components/Loaders/Loader'
import ContentLoader from '../Components/Loaders/Contentloader'
import StudentInfo from './viewDetails'
const {
    generateVC,
    VerifiableCrenticalVC,
    generateKeysAdmin
} = data
const NewStudent = (props) => {

    const [studentList, setStudentList] = useState([])
    const [vc, setVc] = useState('')
    const [verifyVC, setVerifyVC] = useState("")
    const [vCData, setVCData] = useState('')
    const [verifiableCredential, setVerifiableCredential] = useState("")
    const [totalCount, setTotalCount] = useState('0')
    const [search, setSearch] = useState("");
    const [page, setPage] = useState("1")
    const [limit, setLimit] = useState("10")
    const [dataPerPage, setDataPerPage] = useState(0)
    const [publicKey, setPublicKey] = useState('')
    const [publicData, setPublicData] = useState('')
    const [studentRegistrationNumber, setStudentRegistrationNumber] = useState('')
    const [studentNumber, setStudentNumber] = useState('')
    const [studentEmail, setStudentEmail] = useState('')
    const [studentPhone, setStudentPhone] = useState('')
    const [centerNumber, setCenterNumber] = useState('')
    const [courseName, setCourseName] = useState([])
    const [completionDate, setCompletionDate] = useState('')
    const [expiryDate, setExpiryDate] = useState('')
    const [subjects, setSubjects] = useState([])
    const [studentName, setStudentName] = useState('')
    const [modules, setModules] = useState('')
    const [startDate, setStartDate] = useState('')
    const [gradeOverAll, setGradeOverall] = useState('')
    const [status, setStatus] = useState('')
    const [issuranceDate, setIssuanceDate] = useState('')
    const [userDid, setUserDid] = useState('')
    const [privateKey, setPrivateKey] = useState('')
    const [earthId, setEarthId] = useState('')
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)


    useEffect(() => {

        getStudentList();


    }, [])

    const getStudentList = () => {
        setLoadingPage(true)
        Axios.get(`/getstudentListAdmin?search=${search}&page=${page}&limit=${limit}`)
            .then((response) => {
                setLoadingPage(false)
                let pageData = Math.ceil(response.data.total / limit)
                // console.log("pageData", response.data.length, response)
                //  console.log("student List", response)
                setStudentList(response.data.students)
                setTotalCount(response.data.total)
                setDataPerPage(pageData)
            })
            .catch((error) => {

            })
    }



    const sendMail = (data) => {
        //  console.log("mail data", data)

        let emailValue = {
            email: data.email_id,
            studentNo: data.student_number,


        }

        console.log("email values", emailValue)
        setLoading2({ [data.student_number]: true })
        Axios.post("/sentmail", emailValue)
            .then((response) => {
                setLoading2({ [data.studentNo]: false })
                //  getStudentList()
                //  console.log("response email", response)
                if (response.data.message == "check your mail box") {
                    store.addNotification({

                        title: "Success!",

                        message: "Qr Code is Sent on Your Registred Mail",

                        type: "success",

                        insert: "top",

                        container: "top-right",

                        animationIn: ["animated", "fadeIn"],

                        animationOut: ["animated", "fadeOut"],

                        dismiss: {

                            duration: 3000,

                            onScreen: true,

                        },

                    });

                } else {
                    store.addNotification({

                        title: "Error!",

                        message: "Mail not sent",

                        type: "danger",

                        insert: "top",

                        container: "top-right",

                        animationIn: ["animated", "fadeIn"],

                        animationOut: ["animated", "fadeOut"],

                        dismiss: {

                            duration: 3000,

                            onScreen: true,

                        },

                    });

                }


            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handlePageClick = (event) => {
        console.log("page event", event)
        setPage(event.selected + 1)
    };
    useEffect(() => {
        getStudentList()
    }, [page])

    useEffect(() => {
        getStudentList()
    }, [search])




    useEffect(() => {
        let updateData = {
            student_registration_number: studentRegistrationNumber,
            student_number: studentNumber,
            email_id: studentEmail,
            phone_number: studentPhone,
            student_name: studentName,
            modules: modules,
            start_date: startDate,
            completion_date: completionDate,
            grade_overall: gradeOverAll,
            status: status,
            center_number: centerNumber,
            issuance_date: issuranceDate,
            expiry_date: expiryDate,
            course_name: courseName,
            subjects: subjects,
            publicKey: publicKey,
            privateKey: privateKey,
            userDid: userDid,
            earthId: earthId
        }

        Axios.patch('/updateStudent', updateData)
            .then((response) => {

                // console.log('updates student response', response)
                getStudentList()




            })
            .catch((error) => {

            })


    }, [userDid])

    const registerUser = (email, studentNo, studentPhoneNo, data) => {
        //  console.log("data 385", data)
        setStudentRegistrationNumber(data.student_registration_number)
        setStudentNumber(data.student_number)
        setStudentEmail(data.email_id)
        setStudentPhone(data.phone_number)
        setSubjects(data.subjects)
        setCourseName(data.course_name)
        setStudentName(data.student_name)
        setModules(data.modules)
        setStartDate(data.start_date)
        setCompletionDate(data.completion_date)
        setGradeOverall(data.grade_overall)
        setStatus(data.status)
        setCenterNumber(data.center_number)
        setIssuanceDate(data.issuance_date)
        setExpiryDate(data.expiry_date)
        setCourseName(data.course_name)

        setLoading({ [studentNo]: true })
        generateKeysAdmin()
            .then((data) => {
                setPublicKey(data.data.publicKey)
                setPrivateKey(data.data.privateKey)
                setLoading({ [studentNo]: false })
                if (data.data.publicKey) {
                    return new Promise((resolve, reject) => {
                        try {
                            var config = {
                                method: "post",
                                url: 'https://stage-apiv2.myearth.id/user/registration',
                                headers: {
                                    "Content-Type": "application/json",

                                },
                                data: {
                                    "username": studentNo,
                                    // "deviceID": "bhima0830548595076960000910",
                                    deviceID: Math.random().toString(36).slice(2),
                                    "deviceIMEI": "bhimaa",
                                    "deviceName": "EarthId",
                                    "email": email,
                                    "orgId": "234640ae-885f-4241-bc1b-10d2eb0295dc",
                                    "phone": studentPhoneNo,
                                    "countryCode": "+1",
                                    "publicKey": data.data.publicKey,
                                    "deviceOS": "android"



                                }

                            };


                            Axios(config, { crossdomain: true })
                                .then((res) => {
                                    //  console.log(" register  response", res)
                                    //  console.log("earth id 418", res.data.earthId)
                                    setEarthId(res.data.earthId)
                                    getStudentList()
                                    if (res.data.earthId) {
                                        store.addNotification({

                                            title: "Success!",

                                            message: "User Registration Completed",

                                            type: "success",

                                            insert: "top",

                                            container: "top-right",

                                            animationIn: ["animated", "fadeIn"],

                                            animationOut: ["animated", "fadeOut"],

                                            dismiss: {

                                                duration: 3000,

                                                onScreen: true,

                                            },

                                        });
                                    }
                                    if (res.data.publicKey) {
                                        return new Promise((resolve, reject) => {
                                            try {
                                                var config = {
                                                    method: "get",
                                                    url: 'https://ssi-test.myearth.id/api/user/did',
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                        "X-API-KEY": "01a41742-aa8e-4dd6-8c71-d577ac7d463c",
                                                        'publicKey': res.data.publicKey,

                                                    },
                                                };

                                                Axios(config, { crossdomain: true })
                                                    .then((res) => {

                                                        console.log("user did response", res.data.data)
                                                        setUserDid(res.data.data)
                                                        return resolve(res.data);
                                                    })

                                                    .catch((e) => {


                                                    });
                                            }
                                            catch (e) {
                                                console.log("Error in qr Code ", e);
                                                return reject(e);
                                            }
                                        });


                                    }
                                    return resolve(res.data);

                                })
                                .catch((e) => {
                                    
                                    console.log("This is e  @@@@@", e.response.data.message);
                                    if (e.response.data.message == "email already Exist") {
                                        store.addNotification({

                                            title: "Error!",

                                            message: "User already Registred",

                                            type: "danger",

                                            insert: "top",

                                            container: "top-right",

                                            animationIn: ["animated", "fadeIn"],

                                            animationOut: ["animated", "fadeOut"],

                                            dismiss: {

                                                duration: 3000,

                                                onScreen: true,

                                            },

                                        });
                                    }
                                    else {
                                        store.addNotification({

                                            title: "Error!",

                                            message: e.response.data.message,

                                            type: "danger",

                                            insert: "top",

                                            container: "top-right",

                                            animationIn: ["animated", "fadeIn"],

                                            animationOut: ["animated", "fadeOut"],

                                            dismiss: {

                                                duration: 3000,

                                                onScreen: true,

                                            },

                                        });

                                    }



                                });
                        }

                        catch (e) {
                            console.log("Error in qr Code ",);
                            return reject(e);

                        }
                    });
                }
            })


            .catch((error) => {
                setLoading({ [studentNo]: false })              
                store.addNotification({

                    title: "Error!",

                    message: error,

                    type: "danger",

                    insert: "top",

                    container: "top-right",

                    animationIn: ["animated", "fadeIn"],

                    animationOut: ["animated", "fadeOut"],

                    dismiss: {

                        duration: 3000,

                        onScreen: true,

                    },

                });

            })


    }




    return (
        <>
            <div class="middleSection-wrapper" >
                <div class="dashboardOuter">

                    <div class="rightSection">


                        <div class="container">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="student_Title" >
                                    <h1> Total Student : {totalCount}</h1>



                                </div>

                                <div className="">
                                    <div class="input-group">
                                        <div class="form-outline">
                                            <input type="search" placeholder="Search" id="form1" class="form-control searchBar" name="search" value={search} onChange={e => setSearch(e.target.value)} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {
                                loadingPage == true ? <ContentLoader /> :

                                    <div class="tableWrapper">


                                        <table class="table table-bordered">

                                            <thead>
                                                <tr>
                                                    <th scope="col">S.No.</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Registration No.</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Phone No.</th>
                                                    <th scope="col">Generate Wallet</th>
                                                    <th scope="col">Email QR</th>
                                                    <th scope="col">Actions</th>




                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    studentList && studentList.length > 0 ?
                                                        studentList.map((data, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{data.student_name}</td>
                                                                    <td>{data.student_number}</td>


                                                                    <td>
                                                                        {data.email_id}

                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            data.phone_number
                                                                        }


                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            data.userDid ?
                                                                                <button className="generateVCAdmin" style={{ background: '#ccc' }} disabled>Register</button>
                                                                                :
                                                                                <>
                                                                                    {

                                                                                        loading[data.student_number] === true ? <Loader />
                                                                                            :
                                                                                            <button className="generateVCAdmin" onClick={() => registerUser(data.email_id, data.student_number, data.phone_number, data)}>Register</button>

                                                                                    }
                                                                                </>


                                                                        }


                                                                    </td>



                                                                    <td>

                                                                        {

                                                                            data.userDid ?
                                                                                <>
                                                                                    {
                                                                                        loading2[data.student_number] === true ? <Loader />
                                                                                            :
                                                                                            <button className="generateVCAdmin" onClick={() => sendMail(data)}>Email</button>


                                                                                    }



                                                                                </>
                                                                                : <button className="generateVCAdmin" style={{ background: '#ccc' }} disabled >Email</button>



                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        {/* <>
                                                                            <button type="button" class="btn btn-primary " style={{ width: '140px', height: '40px', marginLeft: '10px', borderRadius: '25px', fontSize: '18px', fontWeight: '600', backgroundColor: "#007bff", borderColor: "#007bff", color: '#fff' }} data-toggle="modal" data-target="#exampleModalqr">
                                                                                View Details
                                                                            </button>


                                                                            <div class="modal fade" id="exampleModalqr" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelqr" aria-hidden="true">
                                                                                <div class="modal-dialog" role="document">
                                                                                    <div class="modal-content">
                                                                                        <div class="modal-header">
                                                                                            <h5 class="modal-title" id="exampleModalLabelqr">Student information</h5>
                                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                                <span aria-hidden="true">&times;</span>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div class="modal-body" style={{ textAlign: 'center' }}>
                                                                                            <span>Student Name :{data.student_name}</span>


                                                                                        </div>
                                                                                        <div class="modal-footer">
                                                                                            <button type="button" class="btn btn-secondary login_button" data-dismiss="modal">Close</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> */}
                                                                        <StudentInfo studentData={data} />



                                                                    </td>



                                                                </tr>

                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td style={{ textAlign: 'center' }} colSpan={8}>Data not available</td>
                                                        </tr>

                                                }

                                            </tbody>
                                        </table>


                                    </div>
                            }
                            <div className="list-pagination">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    pageCount={dataPerPage}
                                    previousLabel="previous"
                                    renderOnZeroPageCount={null}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                />
                            </div>

                        </div>

                    </div>

                </div>
            </div >


        </>
    )
}
export default NewStudent;
