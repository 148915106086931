import React, { Component, } from 'react'
import Header from "../Components/CommonComponents/Header/Header";
import Sidebar from '../Components/CommonComponents/Sidebar/Sidebar';
import GenerateVC from '../AdminGenrateVC/GenerateVC';
import Footer from '../Components/CommonComponents/Footer/Footer'

export default class Transcript extends Component {

    constructor(props){
        super(props)
        this.state ={

        }
    } 
    render() {
        return (
            <>
              <Header />
              <Sidebar />
              <GenerateVC />
            
              <Footer/>
            </>
        )
    }


}
