

import React, { Component } from "react";

import data from "../../Api.js"

import "./RegisterVender.css";

import { store } from "react-notifications-component";
import Axios from 'axios'


import crypto from 'crypto'

const {

    listenForUserData,

    listenForServiceProviderResponse,

    generateQrCodeNest,

    registerVendor,

} = data;




let showNotification = true;




class RegisterVender extends Component {

    state = {

        showSpinner: false,

        showBtn: true,

        requestNo: "",

        qrcode: false,

        response: false,

        btnText: "Login with EarthID",

        showText: true,

        showModal: true,

        modalHeading: false,
        errorMsg :""

    };





    userDenied = () => {

        this.setState({

            response: false,

            showModal: false,

        });

        setTimeout(() => {

            document.getElementById("exampleModalLogin").click();

            if (showNotification === true) {

                showNotification = false;

                store.addNotification({

                    title: "Error!",

                    message: `User Didn't Authorize!`,

                    type: "danger",

                    insert: "top",

                    container: "top-right",

                    animationIn: ["animated", "fadeIn"],

                    animationOut: ["animated", "fadeOut"],

                    dismiss: {

                        duration: 3000,

                        onScreen: true,

                    },

                });

            }

        }, 0);

    };





    updateNotificationState = () => {

        setTimeout(() => {

            showNotification = true;

        }, 100);

        this.redirectToGenerateCred();

    };






    listenForSocket = () => {

        listenForServiceProviderResponse((err, res) => {

            // console.log("nikhil", res);

            this.setState({

                qrcode: false,

                response: false,

                showSpinner: true,

                showText: false,

            });

        });


        listenForUserData(async (err, res) => {
            console.log("resopose 162", res)
            console.log("resopose 162", res.newreq.userName)
            if (res.newreq.userName) {
                Axios.get(`/getOneStudent?studentNo=${res.newreq.userName}`)
                    .then((response) => {
                        console.log("response", response)
                        localStorage.setItem('studentData', JSON.stringify(response.data))
                        this.setState({errorMsg :response.data})
                        if (response.data == "Student not found") {


                        } else{
                            setTimeout(()=>{
                               window.location.href = '/transcript'

                            },2000)
                           
                        }
                           

                       


                    })
                    .catch((error) => {
                        console.log(error)
                    })




            }

            let bytes = res.userDetails || res.newreq;




            // Save bytes data to sessionStorage

            sessionStorage.setItem('profileData', JSON.stringify(bytes));

            console.log('Got Session Data')

            this.setState({

                showQr: false,

                showSpinner: false,

            });

            let creditnals;

            let sessionKey;

            if (bytes === "denied" || bytes.permission === "denied") {

                this.userDenied();

            } else if (

                bytes.permission === "granted" ||

                bytes === "issuerLogin" ||

                bytes === "credentialsgenerated" ||

                bytes === "verifylogin" ||

                bytes === "success"

            ) {

                this.setState({

                    response: true,

                    showSpinner: false,

                    loggedIn: true,

                    showModal: false,

                    btnText: "LogOut",

                    bytes: "issuerLogin",

                });

                setTimeout(() => {

                    document.getElementById("exampleModalLogin").click();

                    let popMessage;

                    if (showNotification === true) {

                        if (this.state.bytes === "credentialsgenerated") {

                            popMessage = "Credentials Generated";

                        } else {

                            popMessage = `Login Successful`;

                        }

                        showNotification = false;

                        store.addNotification({

                            title: "Success!",

                            message: popMessage,

                            type: "success",

                            insert: "top",

                            container: "top-right",

                            animationIn: ["animated", "fadeIn"],

                            animationOut: ["animated", "fadeOut"],

                            dismiss: {

                                duration: 3000,

                                onScreen: true,

                            },

                        });

                        if (bytes.permission === "granted") {

                            this.updateNotificationState();

                        }

                    }

                }, 0);

            } else {

                this.setState({

                    response: false,

                    showModal: false,

                });

                setTimeout(() => {

                    document.getElementById("exampleModalLogin").click();

                    if (showNotification === true) {

                        showNotification = false;

                        store.addNotification({

                            title: "Error!",

                            message: `User Didn't Authorize!`,

                            type: "danger",

                            insert: "top",

                            container: "top-right",

                            animationIn: ["animated", "fadeIn"],

                            animationOut: ["animated", "fadeOut"],

                            dismiss: {

                                duration: 3000,

                                onScreen: true,

                            },

                        });

                    }

                }, 0);

            }

        });

    };






    generateQrCodeData = async () => {




        registerVendor()

            .then((d) => {

                const timestamp = Date.now();

                const hash = crypto

                    .createHmac("sha256", d.secretKey)

                    .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))

                    .digest("hex");

                console.log('Old hash', hash)

                generateQrCodeNest(hash, d.apiKey, "Rejolut@1008", timestamp, "login")

                    .then((data) => {

                        this.listenForSocket();

                        this.setState({

                            showQr: true,

                            qrCodeData: data,

                            apiKey: this.state.apikey,

                            qrcode: false,

                        });

                    })

                    .catch((err) => {

                        alert(err);

                    });

            })

            .catch((err) => {

                alert(err);

            });

    };




    //Admin functions





    adminNotificationState = () => {

        setTimeout(() => {

            showNotification = true;

        }, 100);

        this.redirectToAdmin();

    };





    redirectToAdmin() {

        window.location.href = "/admin";

    }




    listenForSocketAdmin = () => {

        listenForServiceProviderResponse((err, res) => {

            // console.log("nikhil", res);

            this.setState({

                qrcode: false,

                response: false,

                showSpinner: true,

                showText: false,

            });

        });




        listenForUserData(async (err, res) => {
            console.log("450", res)

            let bytes = res.userDetails || res.newreq;

            console.log("bytes", bytes);

            this.setState({

                showQr: false,

                showSpinner: false,

            });

            let creditnals;

            let sessionKey;

            if (bytes === "denied" || bytes.permission === "denied") {

                this.userDenied();

            } else if (

                bytes.permission === "granted" ||

                bytes === "issuerLogin" ||

                bytes === "credentialsgenerated" ||

                bytes === "verifylogin" ||

                bytes === "success"

            ) {

                this.setState({

                    response: true,

                    showSpinner: false,

                    loggedIn: true,

                    showModal: false,

                    btnText: "LogOut",

                    bytes: "issuerLogin",

                });

                setTimeout(() => {

                    document.getElementById("exampleModalLogin").click();

                    let popMessage;

                    if (showNotification === true) {

                        if (this.state.bytes === "credentialsgenerated") {

                            popMessage = "Credentials Generated";

                        } else {

                            popMessage = `Login Successful`;

                        }

                        showNotification = false;

                        store.addNotification({

                            title: "Success!",

                            message: popMessage,

                            type: "success",

                            insert: "top",

                            container: "top-right",

                            animationIn: ["animated", "fadeIn"],

                            animationOut: ["animated", "fadeOut"],

                            dismiss: {

                                duration: 3000,

                                onScreen: true,

                            },

                        });

                        if (bytes.permission === "granted") {

                            this.adminNotificationState();

                        } else if (bytes === "verifylogin") {

                            this.verifyNotificationState();

                        }

                    }

                }, 0);

            } else {

                this.setState({

                    response: false,

                    showModal: false,

                });

                setTimeout(() => {

                    document.getElementById("exampleModalLogin").click();

                    if (showNotification === true) {

                        showNotification = false;

                        store.addNotification({

                            title: "Error!",

                            message: `User Didn't Authorize!`,

                            type: "danger",

                            insert: "top",

                            container: "top-right",

                            animationIn: ["animated", "fadeIn"],

                            animationOut: ["animated", "fadeOut"],

                            dismiss: {

                                duration: 3000,

                                onScreen: true,

                            },

                        });

                    }

                }, 0);

            }

        });

    };




   




    generateQrCodeDataAdmin = async () => {




        registerVendor()

            .then((d) => {

                const timestamp = Date.now();

                console.log(d.apiKey, d.secretKey)

                const hash = crypto

                    .createHmac("sha256", d.secretKey)

                    .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))

                    .digest("hex");

                //const hash = this.generateHash(d.apiKey, d.secretKey, timestamp)

                console.log('New Hash', hash)

                generateQrCodeNest(hash, d.apiKey, "Rejolut@1008", timestamp, "login")

                    .then((data) => {

                        this.listenForSocketAdmin();

                        this.setState({

                            showQr: true,

                            qrCodeData: data,

                            apiKey: this.state.apikey,

                            qrcode: false,

                        });

                    })

                    .catch((err) => {

                        alert(err);

                    });

            })

            .catch((err) => {

                alert(err);

            });

    };




    //Admin Functions end




    qrcode = () => {

        let { qrCodeData } = this.state;



        return (

            <div className="qr-container">

                <img src={qrCodeData} alt="new" />

            </div>

        );

    };




    redirectToHome() {

        window.location.href = "/";

    }

    redirectToGenerateCred() {

        //  window.location.href = "/uploadDoc";
        // window.location.href = "/student_number";
        
        
       

    }







    render() {
       
        let showButton = this.state.showSpinner ? (

            <div className="spinner-div">

                {/* <img className="spinner" src={require("./spinner.gif")} /> */}

            </div>

        ) : null;

        return (
           
            <div className='container-fluid adminLogin'>

            <div className="login_wrapper register_venderHeader">

                <div className="" style={{ textAlign: 'center' }}>
                    <img src="/Logo1.png" alt="img" />
                </div>
               
                <nav className="navbar navbar-expand-sm navbar-light">

                    <div

                        className=" collapse navbar-collapse navbar_center"

                        id="navbarTogglerDemo01"

                    >

                        <div className="clickable" onClick={() => this.redirectToHome()}>

                            {/* <img src={require("./img/Logo-EarthID-white.png")} alt="logo" className="logo" width="140" height="50" />{" "} */}

                        </div>






                        {localStorage.user_name ? (

                            <div>

                                <label className="welcomeLabel">{`Welcome, ${localStorage.getItem(

                                    "user_name"

                                )}`}</label>

                            </div>

                        ) : null}




                        <div className="profileAccess">




                            {window.location.pathname == "/" ? (

                                <div>

                                    <button

                                        type="button"

                                        className="nav-buttons login_button"

                                        data-toggle="modal"

                                        data-target="#exampleModalLogin"

                                        onClick={this.state.btnText == "LogOut" ? this.redirectToHome : this.generateQrCodeData}


                                    >

                                         Login

                                    </button>

                                    {/* <button

                                        type="button"

                                        className="nav-buttons login_button"

                                        data-toggle="modal"

                                        data-target="#exampleModalLogin"

                                        onClick={this.state.btnText == "LogOut" ? this.redirectToHome : this.generateQrCodeDataAdmin}

                                        style={{ marginRight: "2rem", marginLeft: "2rem", marginTop: "1.7rem" }}

                                    >

                                        Admin Login

                                    </button> */}

                                </div>

                            ) : (

                                <div>

                                    <button

                                        type="button"

                                        className="nav-buttons login_button"

                                        data-toggle="modal"

                                        data-target="#exampleModalLogin"

                                        onClick={this.redirectToHome}

                                        style={{ marginRight: "2rem", marginLeft: "2rem", marginTop: "1.7rem" }}

                                    >

                                        Logout

                                    </button>



                                </div>

                            )}

                        </div>

                    </div>

                </nav>





                {/* <div className="modal_container" id="modal-container">

            <div className="modal">

              <div className="qr-code">

                <img

                  src='https://api.qrserver.com/v1/create-qr-code/?data={"apikey":"UKURGTREFATPSYJ","reqNo":"BAZQUVRDTLF","sessionKey":"BAZQUVRDTLF","requestType":"login","encryptionkey":"loginDetailsEncryption" }'

                  alt="QR-Code"

                />

              </div>




              <button id="btn-close">Close me</button>

            </div>

          </div> */}




                <div className="hero-section">

                    <div className="shoppingCart"></div>

                    <div

                        className="modal fade"

                        id="exampleModalLogin"

                        tabIndex="-1"

                        role="dialog"

                        aria-labelledby="exampleModalLabel"

                        aria-hidden="true"

                    >

                        <div className="modal-dialog" role="document">

                            {

                                <div>

                                    {this.state.showModal ? (

                                        <div className="modal-content" style={{ textAlign: 'center' }}>

                                            <div className="modal-header">

                                                <h4 style={{ color: "black" }}>

                                                    {this.state.modalHeading && !this.state.showSpinner

                                                        ? "Use Earth ID app to scan the QR code"

                                                        : "Waiting for user authorization"}

                                                </h4>

                                            </div>

                                            <div className="modal-body">

                                                <div className="pop-upbox">

                                                    {showButton}

                                                    {this.state.response

                                                        ? null

                                                        : this.state.qrcode

                                                            ? this.generateQrCodeData()

                                                            : null}

                                                    {this.state.showQr ? (

                                                        this.qrcode()

                                                    ) : this.state.showSpinner ? (

                                                        <img

                                                            className="spinner"

                                                        //   src={require("./spinner.gif")}

                                                        />

                                                    ) : null}

                                                </div>

                                            </div>

                                            <div className="modal-footer">

                                                <button

                                                    type="button"

                                                    className="btn btn-secondary p-btn login_button"

                                                    data-dismiss="modal"

                                                >

                                                    Close

                                                </button>

                                            </div>

                                        </div>

                                    ) : null}

                                </div>

                            }

                        </div>

                    </div>

                </div>









            </div>
            </div>
           
            

        );
       


    }

}




export default RegisterVender;