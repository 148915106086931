import axios from "axios";
import openSocket from "socket.io-client";

const base_url = "https://stage-apiv2.myearth.id";
const apiKey = "UKURGTREFATPSYJ";

let apiAuthorization = "fae2622d-7b73-4fc6-a536-202cabe75187";
const socketURL = "https://stage-socketv2.myearth.id";
const socket = openSocket(socketURL)

let socketId;
socket.on("connect", () => {
    socketId = socket.id;
    console.log(socketId)
});
let transcriptData = JSON.parse(localStorage.getItem("studentData"))
let profileDetails = JSON.parse(sessionStorage.getItem('profileData'))
let vcData = JSON.parse(sessionStorage.getItem('VCData'))




const externalFunctions = {
    async getApiKey() {
        return apiKey;
    },
    async registerVendor() {
        return new Promise((resolve, reject) => {
            try {
                var config = {
                    method: "post",
                    url: `${base_url}/customer/registerVendor`,
                    headers: {
                        "Content-Type": "application/json",
                        "X-API-KEY": apiAuthorization,
                    },
                    data: {
                        vendorName: "vendor",
                        docsRequired: ["ID", "DL"],
                        webhook_url: "http://localhost:3000/user/getUser",
                        adminKey: "Rejolut@1008",
                        partnerOrgId: ""
                    },
                };
                axios(config, { crossdomain: true })
                    .then((res) => {
                        console.log("register Vender", res)
                        return resolve(res.data);
                    })
                    .catch((e) => {
                        console.log("This is e ", e);
                        return reject("unable to get secret Token");
                    });
            } catch (e) {
                console.log("Error in qr Code ", e);
                return reject(e);
            }
        });
    },
    async generateQrCodeNest(signature, apiKey, nonce, timestamp, requestType) {
        return new Promise((resolve, reject) => {
            try {
                var config = {
                    headers: {
                        authkey: apiAuthorization,
                        "x-request-signature-sha-256": "" + signature + "",
                        "api-key": apiKey,
                        nonce: nonce,
                        timestamp: timestamp,
                    },
                };

                axios
                    .get(
                        `${base_url}/customer/generateQr?socketId=${socketId}&requestType=${requestType}`,
                        config,
                        { crossdomain: true }
                    )
                    .then((res) => {
                        console.log("generate qr code ", res)
                        console.log(res.data)
                        return resolve(res.data);
                    })
                    .catch((e) => {
                        console.log("This is e ", e);
                        return reject("unable to get secret Token");
                    });
            } catch (e) {
                console.log("Error in qr Code ", e);
                return reject(e);
            }
        });
    },
    async listenForServiceProviderResponse(cb) {
        socket.on(`sendServiceProvider`, (data) => {
            cb(null, data);
            console.log(data)
        });
    },
    async listenForUserData(cb) {
        console.log("listenForServiceProviderResponse");
        socket.on(`userdata`, (data) => {
            if (data && !data.error) {
                console.log("listenForUserData : ", data);
                cb(null, data);
            }
        });
    },
    async generateVC() {
        return new Promise((resolve, reject) => {
            try {
                var config = {
                    method: "post",
                    url: 'https://ssi-test.myearth.id/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false',
                    headers: {
                        "Content-Type": "application/json",
                        "X-API-KEY": "01a41742-aa8e-4dd6-8c71-d577ac7d463c",
                        "did": profileDetails.userDid,
                        'publicKey': profileDetails.publicKey,

                    },
                    data: {
                        "schemaName": "IccaVCSchema:1",
                        "isEncrypted": false,
                        "dependantVerifiableCredential": [],
                        "credentialSubject": {
                            "earthId": profileDetails.earthId,
                            "studentTranscript": {
                                "_id": transcriptData._id,
                                "student_registration_number": transcriptData.student_registration_number,
                                "student_number": transcriptData.student_number,
                                "course_name": transcriptData.course_name,
                                "subjects": transcriptData.subjects,
                                "completion_date": transcriptData.completion_date,
                                "expiry_date": transcriptData.expiry_date,
                                "grade_overall": transcriptData.grade_overall,
                                "createdAt": transcriptData.createdAt,
                                "issuance_date": transcriptData.issuance_date,
                                "modules": transcriptData.modules,
                                "start_date": transcriptData.start_date,
                                "status": transcriptData.status,
                                "student_name": transcriptData.student_name
                            }

                        }
                    }



                };
                console.log("VC body", config)

                axios(config, { crossdomain: true })
                    .then((res) => {
                        console.log("generate vc", res)
                        return resolve(res.data);
                    })
                    .catch((e) => {
                        console.log("This is e ", e);
                        return reject("unable to generate VC");
                    });
            } catch (e) {
                console.log("Error in qr Code ", e);
                return reject(e);
            }
        });
    },

    async VerifiableCrenticalVC() {

        return new Promise((resolve, reject) => {
            try {
                var config = {
                    method: "post",
                    url: 'https://ssi-test.myearth.id/api/verifier/verify?verifyParams=&zpkValidation=false',
                    headers: {
                        "Content-Type": "application/json",
                        "X-API-KEY": "01a41742-aa8e-4dd6-8c71-d577ac7d463c",
                        'publicKey': profileDetails.publicKey,

                    },
                    data: {
                        "credentials": vcData.data.verifiableCredential

                    }



                };

                axios(config, { crossdomain: true })
                    .then((res) => {
                        console.log("verifi vc", res)
                        return resolve(res.data);
                    })
                    .catch((e) => {
                        console.log("This is e ", e);
                        return reject("unable to verfi VC");
                    });
            }
            catch (e) {
                console.log("Error in qr Code ", e);
                return reject(e);
            }
        });
    },
    async generateKeysAdmin() {

        return new Promise((resolve, reject) => {
            try {
                var config = {
                    method: "get",
                    url: 'https://ssi-test.myearth.id/api/user/generateKeyPair',
                    headers: {
                        "Content-Type": "application/json",
                        "X-API-KEY": "01a41742-aa8e-4dd6-8c71-d577ac7d463c",


                    },




                };

                axios(config, { crossdomain: true })
                    .then((res) => {
                        console.log("generate key response", res)


                        return resolve(res.data);

                    })
                    .catch((e) => {
                        console.log("This is e ", e);
                        return reject("unable to generate keys");
                    });
            }
            catch (e) {
                console.log("Error in qr Code ", e);
                return reject(e);
            }
        });
    }



}

export default externalFunctions;
