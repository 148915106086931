import React, { useState } from 'react';
import './login.css'
import Axios from 'axios'
import { store } from 'react-notifications-component';

const Login = () => {

    const [emailerror, setEmailError] = useState("");
    const [passworderror, setPasswordError] = useState("");
    const [inputValues, setInputValues] = useState({
        email: "",
        password: "",
    });

    const inputEvent = (e) => {
        console.log(inputValues);
        var { name, value } = e.target;
        setInputValues((previousValue) => {

            if (name == "email") {

                return {

                    email: value,
                    password: previousValue.password

                }
            } else if (name == "password") {

                return {

                    password: value,
                    email: previousValue.email
                }
            }
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setEmailError("");
        setPasswordError("");
        if (inputValues.email == "") {
            setEmailError("Email is reuired");
            return false;

        } else if (inputValues.password == "") {
            setPasswordError("Password is reuired");
            return false;

        } else {


            let loginValue = {
                email: inputValues.email,
                password: inputValues.password
            }
            console.log(loginValue, "13")
            Axios.post("/adminLogin", loginValue)
                .then((response) => {
                    console.log("61",response.data.data.token)
                    localStorage.setItem('token',JSON.stringify(response.data.data.token))
                    if (response.data.code == 200) {

                        store.addNotification({

                            title: "Success!",

                            message: "Login Sucessfully",

                            type: "success",

                            insert: "top",

                            container: "top-right",

                            animationIn: ["animated", "fadeIn"],

                            animationOut: ["animated", "fadeOut"],

                            dismiss: {

                                duration: 3000,

                                onScreen: true,

                            },

                        });
                      //  sessionStorage.setItem("token",JSON.stringify(response.data.data.token))
                        setTimeout(()=>{
                           window.location.href="/dashboard"

                        },3000)
                       

                    } else if (response.data.code == 500) {
                        store.addNotification({

                            title: "Error!",

                            message: response.data.data,

                            type: "danger",

                            insert: "top",

                            container: "top-right",

                            animationIn: ["animated", "fadeIn"],

                            animationOut: ["animated", "fadeOut"],

                            dismiss: {

                                duration: 3000,

                                onScreen: true,

                            },

                        });

                    }
                    console.log("login response", response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    return (
        <>
            <div className="container-fluid adminLogin">
                <div className='login_wrapper'>
                    <div className='adminwrapper'>
                        <img src="/Logo1.png" alt="logo" style={{ width: '54%', height: '80px', marginLeft: '-20px' }} />

                    </div>
                    <form >
                        <div class="form-group">
                            <label for="exampleInputEmail1">Email address  <span  class="errormsg">*</span></label>
                            <input type="email" class="form-control" name="email" value={inputValues.email} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"
                                onChange={inputEvent}
                            />
                            <small>{emailerror}</small>

                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Password <span >*</span></label>
                            <input type="password" class="form-control" name="password" value={inputValues.password} onChange={inputEvent} id="exampleInputPassword1" placeholder="Password" />
                            <small>{passworderror}</small>
                        </div>
                       
                        <button type="submit" onClick={handleSubmit} class="btn btn-primary loginButtons">Login</button>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Login;