import React, { Component } from "react";
import moment from 'moment'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
const options = {


    marginTop: '-30px'

};


export default class Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
      
        return (
            <>
             <div className='approveInfpop'>

                <button type="button" class="btn btn-primary backbtn1 backbtn" style={{ fontSize: '18px', fontWeight: "600", borderRadius: '25px' }} data-toggle="modal" data-target="#exampleModal1">
                    Download
                </button>

                <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modalOuter1" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className='container' style={{width:'75%',margin:'auto'}} ref={el => (this.componentRef = el)}>
                                    <div className='imagewrapper2'style={{marginlLeft:'-28px'}}>
                                        <img src="/Logo1.png" alt="logo" style={{width:'40%'}}/>
                                    </div>
                                    <div className=''>
                                        <div className='academicTxp1'>
                                            <span className='academicTxt1 academicTxp_pdf1'>Academic Transcript</span>
                                        </div>
                                        <div className='hospital01 hospital01_pdf'>
                                            <span className='academicTxt02'>{this.props.courseName}</span>
                                        </div>
                                        <div className='studentInfo'style={{heigh:'120px'}}>
                                            <ul>
                                                <li style={{ fontWeight: '600', fontSize: '19px', width: '445px' }}><span style={{ fontWeight: '400', width: '130px', display: 'inline-block', fontSize: '19px' }}>Student No</span> : &nbsp; {this.props.studentData.student_number}</li>
                                                <li style={{ fontWeight: '600', fontSize: '19px', width: '476px' }}><span style={{ fontWeight: '400', width: '130px', display: 'inline-block', fontSize: '19px' }}>Student Name</span> :&nbsp; {this.props.studentData.student_name}</li>
                                                <li style={{ fontWeight: '600', fontSize: '19px', width: '445px' }}><span style={{ fontWeight: '400', width: '130px', display: 'inline-block', fontSize: "19px" }}>Date of issues</span> :&nbsp; {moment(this.props.studentData.issuance_date).format('DD-MM-YYYY')}</li>



                                            </ul>
                                        </div>
                                        <div style={{height:'930px'}}>

                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <th className='customcss'>Sr No.</th>
                                                    <th className='customcss' width="40%">Subject.</th>
                                                    <th className='customcss'>Marks%</th>
                                                    <th className='customcss'>Overall%</th>
                                                    <th className='customcss'>Grade</th>
                                                    <th className='customcss'>Result</th>
                                                </tr>
                                                <tbody>
                                                    <tr >
                                                        <td className='operations' style={{ paddingTop: '15px', fontSize: '14px' }}> </td>
                                                        <td className='operations' style={{ paddingTop: '15px', fontSize: '14px' }}> Introduction to Hospiltality Operations</td>
                                                        <td className='operations'></td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>75</td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>M</td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>C</td>
                                                    </tr>
                                                    {
                                                        this.props.subjects && this.props.subjects.length > 0 ?
                                                            this.props.subjects.map((data, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>{index + 1}</td>
                                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>{data.name}</td>
                                                                        <td className='operations2 dataValue' style={{ fontSize: '13px', paddingTop: '5px' }}>{data.mark}</td>
                                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px', textAlign: 'center' }}>{data.grade}</td>
                                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                                    </tr>

                                                                )

                                                            })
                                                            :
                                                            null

                                                    }




                                                    <tr>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>6</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>Understand Own Rates in Self Development</td>
                                                        <td className='operations2 dataValue' style={{ fontSize: '13px', paddingTop: '5px' }}>85</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                    </tr>
                                                    <tr >
                                                        <td className='operations' style={{ paddingTop: '15px' }}> </td>
                                                        <td className='operations' style={{ paddingTop: '15px', fontSize: '14px' }}>Introduction to Kitchen Operations</td>
                                                        <td className='operations' style={{ paddingTop: '15px' }}></td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>66</td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>P</td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>C</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>7</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>Food Safety and HACCP in Catring</td>
                                                        <td className='operations2 dataValue' style={{ fontSize: '13px', paddingTop: '5px' }}>75</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>8</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>Presentation of Food and Assembling of Buffets</td>
                                                        <td className='operations2 dataValue' style={{ fontSize: '13px', paddingTop: '5px' }}>67</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>9</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>Menu Planning Costing and Catering Controls</td>
                                                        <td className='operations2 dataValue' style={{ fontSize: '13px', paddingTop: '5px' }}>60</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                    </tr>
                                                    <tr >
                                                        <td className='operations' style={{ paddingTop: '15px' }}> </td>
                                                        <td className='operations' style={{ paddingTop: '15px', fontSize: '14px' }}>Introduction to Cookery </td>
                                                        <td className='operations' style={{ paddingTop: '15px' }}></td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>67</td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>P</td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>C</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>10</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>Use Basic Methods of Cookery</td>
                                                        <td className='operations2 dataValue' style={{ fontSize: '13px', paddingTop: '5px' }}>75</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>11</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>Organize and Prepare Food</td>
                                                        <td className='operations2 dataValue' style={{ fontSize: '10px', paddingTop: '5px' }}>65</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>12</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>Prepare Foods to Meet Dietary and Cultural Needs</td>
                                                        <td className='operations2 dataValue' style={{ fontSize: '13px', paddingTop: '5px' }}>70</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                    </tr>
                                                    <tr >
                                                        <td className='operations' style={{ paddingTop: '15px' }}> </td>
                                                        <td className='operations' style={{ paddingTop: '15px', fontSize: '14px' }}> Patisserie Major </td>
                                                        <td className='operations' style={{ paddingTop: '15px' }}></td>
                                                        <td className='operations dataValue ' style={{ paddingTop: '15px', fontSize: '14px' }}>90</td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>D</td>
                                                        <td className='operations dataValue' style={{ paddingTop: '15px', fontSize: '14px' }}>C</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>13</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>Prepare Pastry, Cakes and Yeast Goods</td>
                                                        <td className='operations2 dataValue' style={{ fontSize: '13px', paddingTop: '5px' }}>91</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>14</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}>Prepare Hot and Cold Desserts</td>
                                                        <td className='operations2 dataValue' style={{ fontSize: '13px', paddingTop: '5px' }}>80</td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                        <td className='operations2' style={{ fontSize: '13px', paddingTop: '5px' }}></td>
                                                    </tr>

                                                </tbody>

                                            </table>

                                        </div>

                                    </div>
                                    <div className="principleWrapper">
                                        <div className="data1">Principle Administrator

                                        </div>
                                        <div className="data2">see overall for rules to award of grade</div>

                                    </div>
                                    <div className="footer01">
                                        <div className="footerpdf">
                                            <img src="/Logo1.png" alt="logo" style={{marginLeft:'-24px'}} />

                                        </div>
                                        <div className="footerRight" style={{ textAlign: 'end' }}>
                                            <span style={{ fontSize: '9px' }}>Block 8</span>&nbsp;<strong className='boderright'></strong>
                                            <span style={{ fontSize: '9px' }}>First Floor</span>&nbsp;<strong className='boderright'></strong>
                                            <span style={{ fontSize: '9px' }}>Dubai Acknowledge Park</span>&nbsp;<strong className='boderright'></strong>
                                            <span style={{ fontSize: '9px' }}>PD Box 500816</span>&nbsp;<strong className='boderright'></strong>
                                            <span style={{ fontSize: '9px' }}>Dubai UAE</span><br />
                                            <span style={{ fontSize: '10px', paddingLeft: '159px' }}> Tel +97144578811</span><strong className="boderright"></strong><span style={{ fontSize: '10px' }}>Fax +97144578822</span><br />
                                            <span style={{ fontSize: '10px', paddingLeft: '225px' }}> Email : info@iccadubai.ae</span><br />
                                            <span style={{ fontSize: '10px', paddingLeft: '159px' }}> Tel +97144578811</span><strong className="boderright"></strong><span style={{ fontSize: '10px' }}>Fax +97144578822</span><br />


                                        </div>

                                    </div>
                                    <div style={{
                                        height: '100px'
                                    }}>

                                    </div>
                                    <div class="footerTable">
                                        <div className='footerTable1'>
                                            <table style={{ width: '100%' }}>

                                                <tbody>
                                                    <tr>
                                                        <td width='40%'>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="footerText" colSpan="3">Mark range to grade</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='footerTexttd dc'>100%-90%</td>
                                                                        <td className='D dc'>D</td>
                                                                        <td className="dc">Distinction</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='footerTexttd dc'>89%-70%</td>
                                                                        <td className='D dc'>M</td>
                                                                        <td className="dc">Merit</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='footerTexttd dc'>69%-60%</td>
                                                                        <td className='D dc'>P</td>
                                                                        <td className="dc">Pass</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='footerTexttd dc'>59%-0%</td>
                                                                        <td className='D dc'>F</td>
                                                                        <td className="dc">Fail</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td width="20%">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th ></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td ></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td width='40%'>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ textAlign: 'start' }} colSpan="2">Result</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="dc">C</td>
                                                                        <td className='D dc'>Completed</td>

                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="dc">NYC</td>
                                                                        <td className='D dc'>Not yet Completed</td>

                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="dc">EXMP</td>
                                                                        <td className='D dc'>Example</td>

                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="dc">C</td>
                                                                        <td className='D dc'>Completed</td>

                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>

                                        </div>

                                    </div>
                                    



                                </div>

                            </div>
                            <div style={{ textAlign: 'center' }}>
                                        <ReactToPrint className="PrintSection" options={options} documentTitle={"Invoice"} content={() => this.componentRef}>
                                            <PrintContextConsumer >
                                                {({ handlePrint }) => (
                                                    <button className="backbtn generatePdf" onClick={handlePrint}>Print this out!</button>
                                                )}

                                            </PrintContextConsumer>

                                        </ReactToPrint>

                                    </div>


                        </div>
                    </div>
                </div>
                </div>


            </>
        )
    }
}