import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Button, Form, FormGroup, Label, Input, FormText, Modal, ModalBody } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import moment from "moment";
import './NewStudent.css'
export default class invoice extends Component {
    constructor(props) {

        super(props);
        this.state = {
            modal: false

        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {

        var model = !this.state.modal

        this.setState({
            modal: model,

        });

    }
    render() {
     //   console.log("props",this.props.studentData)
        return (

            <div className='approveInfpop'>


                <Button className='viewinvoiceBtn generateVCAdmin' onClick={() => this.toggle(this.props.studentData.id)}>

                    View Details
                </Button>

                <Modal isOpen={this.state.modal} toggle={this.toggle} className='modalOuter'>

                    <Button className='popclose' onClick={this.toggle}>x</Button>

                    <ModalBody >
                        <div >
                            <div className='rejectbookingBody'>

                                <h2>Student Details</h2>
                                <div className="invoiceDetails">
                                    <div className="studentinfoData">
                                        <strong>Student Name</strong>
                                        <span>{this.props.studentData.student_name ? this.props.studentData.student_name : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Registration Number</strong>
                                        <span>{this.props.studentData.student_number ? this.props.studentData.student_number : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Email</strong>
                                        <span>{this.props.studentData.email_id ? this.props.studentData.email_id : 'Not available'} </span>
                                    </div>

                                    <div className="studentinfoData">
                                        <strong>Phone No</strong>
                                        <span>{this.props.studentData.phone_number ? this.props.studentData.phone_number : 'Not available'} </span>
                                        {/* <span>{this.props.studentData.countryCode ? this.props.studentData.countryCode : ''}{this.props.studentData.phone_number ? this.props.studentData.phone_number : 'Not available'} </span> */}
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Course</strong>
                                        <span>{this.props.studentData.course_name ? this.props.studentData.course_name : 'Not available'} </span>

                                        {/* <span>{this.props.studentData.course_name.length > 0 ? this.props.studentData.course_name[0].name : 'Not available'}<br />

                                            &nbsp; &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;   {this.props.studentData.course_name[1] ? this.props.studentData.course_name[1].name : ''}

                                        </span> */}
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Earth Id</strong>
                                        <span>{this.props.studentData.earthId ? this.props.studentData.earthId : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Public key</strong>
                                        <span>{this.props.studentData.publicKey ? this.props.studentData.publicKey : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Private Key</strong>
                                        <span>{this.props.studentData.privateKey ? this.props.studentData.privateKey : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>User Did</strong>
                                        <span>{this.props.studentData.userDid ? this.props.studentData.userDid : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Grade OverAll</strong>
                                        <span>{this.props.studentData.grade_overall ? this.props.studentData.grade_overall : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Status</strong>
                                        <span>{this.props.studentData.status ? this.props.studentData.status : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Modules</strong>
                                        <span>{this.props.studentData.modules ? this.props.studentData.modules : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Issurance Date</strong>
                                        <span>{this.props.studentData.issuance_date ? moment(this.props.studentData.issuance_date).format('ll') : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Completion  Date</strong>
                                        <span>{this.props.studentData.completion_date ? moment(this.props.studentData.completion_date).format('ll') : 'Not available'} </span>
                                    </div>
                                    <div className="studentinfoData">
                                        <strong>Expiry  Date</strong>
                                        <span>{this.props.studentData.expiry_date ? (this.props.studentData.expiry_date) : 'Not available'} </span>
                                    </div>
                                    <div style={{marginTop:'25px',marginBottom:'10px'}}>
                                        <table style={{ width: "100%" }}>
                                            <tr>
                                                <th className='customcss'>Sr No.</th>
                                                <th className='customcss' width="40%">Subject.</th>
                                                <th className='customcss'>Marks%</th>
                                                <th className='customcss'>Overall%</th>
                                                <th className='customcss'>Grade</th>
                                                <th className='customcss'>Result</th>
                                            </tr>
                                            <tbody>
                                                {
                                                 this.props.studentData.subjects && this.props.studentData.subjects.length > 0 ?
                                                 this.props.studentData.subjects.map((data, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className='operations2'>{index + 1}</td>
                                                                    <td className='operations2' style={{ fontSize: '15px' }}>{data.name}</td>
                                                                    <td className='operations2 dataValue'>{data.mark}</td>
                                                                    <td className='operations2'></td>
                                                                    <td className='operations2 dataValue'>{data.grade}</td>
                                                                    <td className='operations2 dataValue'>{data.status}</td>
                                                                </tr>


                                                            )

                                                        })
                                                        :
                                                        null

                                                }
                                            </tbody>
                                        </table>

                                    </div>











                                </div>




                            </div>




                        </div>

                    </ModalBody>


                </Modal>
            </div>


        );
    }
}
