import React, { useEffect, useState } from 'react';
import './dashboard.css'
import Axios from 'axios'


const DashboardMiddle = (props)=>{
const [totalCount,setTotalCount] = useState('')

    useEffect(()=>{
        getStudentList()
    },[])
    const getStudentList = () => {
        Axios.get("/getStudentList")
            .then((response) => {
                console.log("student List", response)
                
                setTotalCount(response.data.total)
            })
            .catch((error) => {

            })
    }
    return(
        <>
        <div class="middleSection-wrapper" >
            <div class="dashboardOuter">
                <div class="rightSection">
                <div class="container">
                        <div class="row">
                            <div class="col-sm-4" style={{marginTop:'20px'}}>
                                <div class="card-box">
                                    <a href="#">
                                        <div class="totlBookingBx"><i class="yellowBg"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg></i><strong>{totalCount}</strong><span>Total Users</span></div>
                                    </a>
                                </div>
                            </div>
                            {/* <div class="col-sm-4" style={{marginTop:'20px'}}>
                                <div class="card-box">
                                    <a href="#">
                                        <div class="totlBookingBx"><i class="pinkBg"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg></i><strong>27</strong><span>Total Users</span></div>
                                    </a>
                                </div>
                            </div> */}
                            {/* <div class="col-sm-4" style={{marginTop:'20px'}}>
                                <div class="card-box">
                                    <a href="#">
                                        <div class="totlBookingBx"><i class="blueBg"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg></i><strong>27</strong><span>Total Users</span></div>
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default DashboardMiddle;