import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
const Loader = props => (
    <div className='loader_wrap'>
    <ThreeDots
  
        height="80"
        width="80"
        radius="9"
        color="#0099ff"
        ariaLabel="three-dots-loading"
        wrapperStyle={{margin:'-21px,10px,10px,10px'}}
        wrapperClassName=""
        visible={true}
       
    />
    </div>

)



export default Loader