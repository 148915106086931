import React  from 'react';
import './Home.css'
import { useState } from "react";
import Axios from 'axios'


const Home = () => {
    const [studentNo, setStudentNo] = useState('')



    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("studentNo", studentNo)
        Axios.get(`/getOneStudent?studentNo=${studentNo}`)
            .then((response) => {
                console.log("response", response)
                localStorage.setItem('studentData', JSON.stringify(response.data))
                if (response.data == "Student not found") {
                    

                } else {
                    window.location.href = '/transcript'

                }


            })
            .catch((error) => {
                console.log(error)
            })


    }

    return (
        <>
            <div className="homeWrapper">
                <div className="homeLogo" >
                    <img src="/Logo1.png" alt="img" style={{ width: '60%' }}></img>
                </div>
                <div className="inputSection">
                    <div></div>
                    <label className="labelValue">Enter the Student Number </label><br/>
                    <input type="number" className="studentNumber" name='studentNo' value={studentNo} onChange={e => setStudentNo(e.target.value)} required /><br/>


                    <button type="submit" className="submit_Button" onClick={handleSubmit}>Submit</button>
                  
                </div>

            </div>

        </>
    )

}
export default Home;