import React, { useState, useEffect, useCallback } from "react";
import data from '../Api'
import Axios from 'axios'
import moment from "moment";
import { store } from "react-notifications-component";
import ReactPaginate from 'react-paginate';
import Loader from '../Components/Loaders/Loader';
import ContentLoader from "../Components/Loaders/Contentloader";
const {
    generateVC,
    VerifiableCrenticalVC
} = data
const GenerateVC = (props) => {

    const [studentList, setStudentList] = useState([])
    const [totalCount, setTotalCount] = useState('0')
    const [search, setSearch] = useState("");
    const [page, setPage] = useState("1")
    const [limit, setLimit] = useState("10")
    const [dataPerPage, setDataPerPage] = useState(0)
    const [loading3, setLoading3] = useState(false)
    const [generateLoader, setGenerateLoader] = useState(false)
   
    useEffect(() => {

        getStudentList();

    }, [])
   


    const getStudentList = () => {
        setGenerateLoader(true)
        Axios.get(`/getstudentListAdmin?search=${search}&page=${page}&limit=${limit}`)
            .then((response) => {
                setGenerateLoader(false)
                let pageData = Math.ceil(response.data.total / limit)
                setStudentList(response.data.students)
                setTotalCount(response.data.total)
                setDataPerPage(pageData)
            })
            .catch((error) => {

            })
    }

    const generateVCData = (data) => {
        console.log(' generate vc data', data)
        if(data.userSignature){

            store.addNotification({

                title: "Error!",

                message: "Vc  already Generated ",

                type: "danger",

                insert: "top",

                container: "top-right",

                animationIn: ["animated", "fadeIn"],

                animationOut: ["animated", "fadeOut"],

                dismiss: {

                    duration: 3000,

                    onScreen: true,

                },

            });

        }   else{  return new Promise((resolve, reject) => {
            try {
                var config = {
                    method: "post",
                    url: 'https://ssi-test.myearth.id/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false',
                    headers: {
                        "Content-Type": "application/json",
                        "X-API-KEY": "01a41742-aa8e-4dd6-8c71-d577ac7d463c",
                        "did": data.userDid,
                        'publicKey': data.publicKey,

                    },
                    data: {
                        "schemaName": "IccaVCSchema:1",
                        "isEncrypted": false,
                        "dependantVerifiableCredential": [],
                        "credentialSubject": {
                            "earthId": data.earthId,
                            "studentTranscript": {
                                "_id": data._id,
                                "student_registration_number": data.student_registration_number,
                                "student_number": data.student_number,
                                "course_name": data.course_name,
                                "subjects": data.subjects,
                                "completion_date": data.completion_date,
                                "expiry_date": data.expiry_date,
                                "grade_overall": data.grade_overall,
                                "createdAt": data.createdAt,
                                "issuance_date": data.issuance_date,
                                "modules": data.modules,
                                "start_date": data.start_date,
                                "status": data.status,
                                "student_name": data.student_name,
                                "earthId": data.earthId,
                                "publicKey": data.publicKey,
                                "privateKey": data.privateKey,
                                "userDid": data.userDid,
                                "email_id": data.email_id,
                                "phone_number": data.phone_number
                            }

                        }
                    }



                };
              //  console.log("VC body", config)
                setLoading3({ [data.student_number]: true })
                Axios(config, { crossdomain: true })
                    .then((res) => {
                    //    console.log("generate vc 100", res)
                        setLoading3({ [data.student_number]: false })
                        if(res.data.data.userSignature){
                            let updateData = {
                                student_registration_number: data.student_registration_number,
                                student_number: data.student_number,
                                email_id: data.email_id,
                                phone_number: data.phone_number,
                                student_name: data.student_name,
                                modules: data.modules,
                                start_date: data.start_date,
                                completion_date: data.completion_date,
                                grade_overall: data.grade_overall,
                                status: data.status,
                                center_number: data.center_number,
                                issuance_date: data.issuanceDate,
                                expiry_date: data.expiry_date,
                                course_name: data.course_name,
                                subjects: data.subjects,
                                publicKey: data.publicKey,
                                privateKey: data.privateKey,
                                userDid: data.userDid,
                                earthId: data.earthId,
                                userSignature :res.data.data.userSignature
                            }
                            Axios.patch('/updateStudent', updateData)
                            .then((response) => {
                           
                            })
                            .catch((error) => {
                
                            })


                        }
                      
                        if (res.data.message) {
                            let saveVc = {
                                userSignature: res.data.data.userSignature,
                                verifiableCredential: {
                                    //  context: data.data.verifiableCredential.context,
                                    context: "https://www.w3.org/2018/credentials/v1",
                                    biometrics: {
                                        face: res.data.data.verifiableCredential.biometrics.face,
                                        finger: res.data.data.verifiableCredential.biometrics.finger,
                                        iris: res.data.data.verifiableCredential.biometrics.iris,
                                    },
                                    credentialSchema: {
                                        id: res.data.data.verifiableCredential.credentialSchema.id,
                                        type: res.data.data.verifiableCredential.credentialSchema.type
                                    },
                                    credentialSubject: [{
                                        id: res.data.data.verifiableCredential.credentialSubject[0].id,
                                        earthId: res.data.data.verifiableCredential.credentialSubject[0].earthId,
                                        studentTranscript: {
                                            completion_date: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.completion_date,
                                            createdAt: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.createdAt,
                                            expiry_date: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.expiry_date,
                                            grade_overall: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.grade_overall,
                                            issuance_date: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.issuance_date,
                                            modules: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.modules,
                                            start_date: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.start_date,
                                            status: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.status,
                                            student_name: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.student_name,
                                            student_number: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.student_number,
                                            student_registration_number: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.student_registration_number,
                                            course_name: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.course_name,
                                            subjects: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.subjects,
                                            publicKey: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.publicKey,
                                            privateKey: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.privateKey,
                                            earthId: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.earthId,
                                            userDid: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.userDid,
                                            email_id: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.email_id,
                                            phone_number: res.data.data.verifiableCredential.credentialSubject[0].studentTranscript.phone_number,


                                        }
                                    }],
                                    expirationDate: res.data.data.verifiableCredential.expirationDate,
                                    id: res.data.data.verifiableCredential.id,
                                    issuanceDate: res.data.data.verifiableCredential.issuanceDate,
                                    issuer: res.data.data.verifiableCredential.issuer,
                                    proof: {
                                        created: res.data.data.verifiableCredential.proof.created,
                                        creator: res.data.data.verifiableCredential.proof.creator,
                                        jws: res.data.data.verifiableCredential.proof.jws,
                                        proofPurpose: res.data.data.verifiableCredential.proof.proofPurpose,
                                        type: res.data.data.verifiableCredential.proof.type,
                                        vcVerificationMethod: res.data.data.verifiableCredential.proof.vcVerificationMethod
                                    },
                                    type: res.data.data.verifiableCredential.type,
                                    version: res.data.data.verifiableCredential.version,
                                    message: res.data.message,
                                    success: res.data.success
                                }



                            }
                            Axios.post("/savevc", saveVc)
                                .then((data) => {
                                    getStudentList()
                                    store.addNotification({

                                        title: "Success!",

                                        message: "Vc Generated Sucessfully",

                                        type: "success",

                                        insert: "top",

                                        container: "top-right",

                                        animationIn: ["animated", "fadeIn"],

                                        animationOut: ["animated", "fadeOut"],

                                        dismiss: {

                                            duration: 3000,

                                            onScreen: true,

                                        },

                                    });




                                })
                                .catch((err) => {
                                    console.log(err)
                                })

                        }
                        return resolve(res.data);
                    })
                    .catch((e) => {
                        console.log("This is e ", e);
                        return reject("unable to generate VC");
                    });
            } catch (e) {
                console.log("2@@@@@@@@@",e)
                return reject(e);
               
            }
        });
    }



    }



    const handlePageClick = (event) => {
        setPage(event.selected + 1)
    };
    useEffect(() => {
        getStudentList()
    }, [page])

    useEffect(() => {
        getStudentList()
    }, [search])

    return (
        <>
            <div class="middleSection-wrapper" >
                <div class="dashboardOuter">
                    <div class="rightSection">


                        <div class="container">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="student_Title" >
                                    <h1> Total Student : {totalCount}</h1>


                                </div>
                                <div className="">
                                    <div class="input-group">
                                        <div class="form-outline">
                                            <input type="search" placeholder="Search" id="form1" class="form-control searchBar" name="search" value={search} onChange={e => setSearch(e.target.value)} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {
                                generateLoader == true ? <ContentLoader /> :

                                    <div class="tableWrapper">

                                        <table class="table table-bordered">

                                            <thead>
                                                <tr>
                                                    <th scope="col">S.No</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Registration No.</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Phone No.</th>

                                                    <th scope="col">Generate VC</th>
                                                    {/* <th scope="col">Verify VC</th> */}





                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    studentList && studentList.length > 0 ?
                                                        studentList.map((data, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row">{index + 1}</th>

                                                                    <td>
                                                                        {data.student_name}
                                                                    </td>
                                                                    <td>
                                                                        {data.student_number}
                                                                    </td>
                                                                    <td>
                                                                        {data.email_id}
                                                                    </td>
                                                                    <td>
                                                                        {data.phone_number}
                                                                    </td>

                                                                    <td>


                                                                        <>
                                                                            {


                                                                                data.userDid ?
                                                                                    <>
                                                                                        {
                                                                                            loading3[data.student_number] === true ? <Loader />

                                                                                                :

                                                                                                <button className="generateVCAdmin" onClick={() => generateVCData(data)}>Generate VC</button>


                                                                                        }
                                                                                    </>
                                                                                    :

                                                                                    <button className="generateVCAdmin" style={{ background: '#ccc' }} disabled >Generate VC</button>



                                                                            }
                                                                        </>

                                                                    </td>







                                                                </tr>

                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td style={{ textAlign: 'center' }} colSpan={8}>Data not available</td>
                                                        </tr>

                                                }

                                            </tbody>
                                        </table>

                                    </div>
                            }
                            
                            <div className="list-pagination">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={dataPerPage}
                                    previousLabel="previous"
                                    renderOnZeroPageCount={null}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                />
                            </div>

                        </div>


                    </div>
                </div>
            </div>


        </>
    )
}
export default GenerateVC;
