import React, { useState } from 'react';
import axios from 'axios';

const UserForm = () => {
    const [studentRegistrationNumber, setStudentRegistrationNumber] = useState('');
    const [studentNumber, setStudentNumber] = useState('');
    const [studentName, setStudentName] = useState('');
    const [courseName, setCourseName] = useState('');
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [userData, setUserData] = useState(null);
    const [subjects, setSubjects] = useState([
        {
            name: '',
            attendance: '',
            grade: '',
            mark: '',
            status: '',
        },
    ]);
    const [modules, setModules] = useState('');
    const [startDate, setStartDate] = useState('');
    const [completionDate, setCompletionDate] = useState('');
    const [attendanceOverall, setAttendanceOverall] = useState('');
    const [gradeOverall, setGradeOverall] = useState('');
    const [status, setStatus] = useState('');
    const [centerNumber, setCenterNumber] = useState('');
    const [issuanceDate, setIssuanceDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');

    const addSubject = () => {
        setSubjects(prevSubjects => [
            ...prevSubjects,
            {
                name: '',
                attendance: '',
                grade: '',
                mark: '',
                status: ''
            }
        ]);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = {
            student_registration_number: studentRegistrationNumber,
            student_number: studentNumber,
            student_name: studentName,
            course_name: courseName,
            subjects,
            modules,
            start_date: startDate,
            completion_date: completionDate,
            attendance_overall: attendanceOverall,
            grade_overall: gradeOverall,
            status,
            center_number: centerNumber,
            issuance_date: issuanceDate,
            expiry_date: expiryDate
        };



        try {
            // Send a POST request to the backend API to store the user data
            await axios.post('http://localhost:3003/api/users', user).then(res => {
                console.log(res, "user added successfully");
                if (res.status == 201) {
                    window.alert("user created")
                }
            })
                .catch(error => {
                    console.log(error)
                })


            // Reset the form fields after successful submission
            setStudentRegistrationNumber('');
            setStudentNumber('');
            setStudentName('');
            setCourseName('');
            setSubjects([
                {
                    name: '',
                    attendance: '',
                    grade: '',
                    mark: '',
                    status: '',
                },
            ]);
            setModules('');
            setStartDate('');
            setCompletionDate('');
            setAttendanceOverall('');
            setGradeOverall('');
            setStatus('');
            setCenterNumber('');
            setIssuanceDate('');
            setExpiryDate('');

        } catch (error) {
            console.error(error);
        }
        // setRegistrationNumber('');

    };


    const handleRetrieveData = async () => {
        try {
            // Send a GET request to retrieve user data based on the registration number
            const response = await axios.get(`http://localhost:3003/api/users/${registrationNumber}`);

            // Access the user data from the response
            const userData = response.data;

            // Set the user data state
            setUserData(userData);
        } catch (error) {
            // Handle error response from the backend API
            console.error(error);
        }
    };

    const handleSubjectChange = (e, index, field) => {
        const updatedSubjects = [...subjects];
        updatedSubjects[index] = {
            ...updatedSubjects[index],
            [field]: e.target.value
        };
        setSubjects(updatedSubjects);
    };
    return (
        <div className='container'>
            <form onSubmit={handleSubmit}>
                <label>
                    Student Registration Number:
                    <input
                        type="text"
                        value={studentRegistrationNumber}
                        onChange={(e) => setStudentRegistrationNumber(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Student Number:
                    <input
                        type="text"
                        value={studentNumber}
                        onChange={(e) => setStudentNumber(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Student Name:
                    <input
                        type="text"
                        value={studentName}
                        onChange={(e) => setStudentName(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Course Name:
                    <input
                        type="text"
                        value={courseName}
                        onChange={(e) => setCourseName(e.target.value)}
                    />
                </label>
                <br />
                {/* Render subjects dynamically */}
                {subjects.map((subject, index) => (
                    <div key={index}>
                        <label>
                            Subject Name:
                            <input
                                type="text"
                                value={subject.name}
                                onChange={(e) => handleSubjectChange(e, index, 'name')}
                            />
                        </label>
                        <br />
                        <label>
                            Attendance:
                            <input
                                type="number"
                                value={subject.attendance}
                                onChange={(e) => handleSubjectChange(e, index, 'attendance')}
                            />
                        </label>
                        <br />
                        <label>
                            Grade:
                            <input
                                type="text"
                                value={subject.grade}
                                onChange={(e) => handleSubjectChange(e, index, 'grade')}
                            />
                        </label>
                        <br />
                        <label>
                            Mark:
                            <input
                                type="text"
                                value={subject.mark}
                                onChange={(e) => handleSubjectChange(e, index, 'mark')}
                            />
                        </label>
                        <br />
                        <label>
                            Status:
                            <input
                                type="text"
                                value={subject.status}
                                onChange={(e) => handleSubjectChange(e, index, 'status')}
                            />
                        </label>
                        <br />
                    </div>
                ))}
                <button type="button" onClick={addSubject}>Add Subject</button>
                <br />
                <label>
                    Modules:
                    <input
                        type="text"
                        value={modules}
                        onChange={(e) => setModules(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Start Date:
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Completion Date:
                    <input
                        type="date"
                        value={completionDate}
                        onChange={(e) => setCompletionDate(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Attendance Overall:
                    <input
                        type="text"
                        value={attendanceOverall}
                        onChange={(e) => setAttendanceOverall(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Grade Overall:
                    <input
                        type="text"
                        value={gradeOverall}
                        onChange={(e) => setGradeOverall(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Status:
                    <input
                        type="text"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Center Number:
                    <input
                        type="text"
                        value={centerNumber}
                        onChange={(e) => setCenterNumber(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Issuance Date:
                    <input
                        type="date"
                        value={issuanceDate}
                        onChange={(e) => setIssuanceDate(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Expiry Date:
                    <input
                        type="text"
                        value={expiryDate}
                        onChange={(e) => setExpiryDate(e.target.value)}
                    />
                </label>
                <br />
                <button type="submit">Submit</button>
            </form>

            <hr />

            <div>
                <label>
                    Registration Number:
                    <input
                        type="text"
                        value={registrationNumber}
                        onChange={(e) => setRegistrationNumber(e.target.value)}
                    />
                </label>
                <button onClick={handleRetrieveData}>Retrieve Data</button>
            </div>
            {userData && (
                <div>
                    <h2>Student Information</h2>
                    <p>Student Registration Number: {userData.student_registration_number}</p>
                    <p>Student Number: {userData.student_number}</p>
                    <p>Student Name: {userData.student_name}</p>
                    <p>Course Name: {userData.course_name}</p>
                    <h3>Subjects</h3>
                    {userData.subjects.map((subject, index) => (
                        <div key={index}>
                            <p>Subject Name: {subject.name}</p>
                            <p>Attendance: {subject.attendance}</p>
                            <p>Grade: {subject.grade}</p>
                            <p>Mark: {subject.mark}</p>
                            <p>Status: {subject.status}</p>
                        </div>
                    ))}
                    <p>Modules: {userData.modules}</p>
                    <p>Start Date: {userData.start_date}</p>
                    <p>Completion Date: {userData.completion_date}</p>
                    <p>Attendance Overall: {userData.attendance_overall}</p>
                    <p>Grade Overall: {userData.grade_overall}</p>
                    <p>Status: {userData.status}</p>
                    <p>Center Number: {userData.center_number}</p>
                    <p>Issuance Date: {userData.issuance_date}</p>
                    <p>Expiry Date: {userData.expiry_date}</p>
                </div>
            )}

        </div>
    );
};

export default UserForm;
