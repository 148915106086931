import React from 'react';
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment'
import StudentPdf2 from '../../Components/modal/StudentPdf2';
import data from '../../Api.js'
import { store } from "react-notifications-component";
import Axios from 'axios'
const {
  generateVC ,
  VerifiableCrenticalVC
} = data

function Student() {
  const [studentData, setStudentData] = useState([])
  const [subjects, setSubjects] = useState([])
  const [courseName, setCourseName] = useState([])



  useEffect(() => {
    var student = JSON.parse(localStorage.getItem("studentData"))
    console.log("2nd student", student)
    setStudentData(student)
    setSubjects(student.subjects)
    setCourseName(student.course_name[1].name)
    
  }, [])
  


  return (
    <div className="container-fluid">
        <div className='container'>
          <div className='pdfWrapper'>
        <div className='imagewrapper' style={{width:'75%',margin:'0px',marginLeft:'120px'}}>
          <img src="/Logo1.png" alt="logo" style={{width:'40%'}} />
        </div>
        <div className='row justify-content-center'>
          <div className='col-lg-9  academicTxp' style={{ marginTop: '0px' }}>
            <span className='academicTxt'>Academic Transcript</span>

            <div style={{ float: 'right',display:'flex',marginTop:'10px'}}>
             <StudentPdf2 studentData={studentData} subjects={subjects} courseName={courseName}/>
             
           
             
            </div>
          </div>
          <div className='col-lg-9  hospital'>
            <span className='academicTxt2'>{courseName}</span>
          </div>
          <div className='col-lg-9  studentInfo'>
            <ul>
              <li style={{ fontWeight: '600' }}><span style={{ fontWeight: '400', width: '200px', display: 'inline-block', fontSize: '22px' }}>Student No</span> : &nbsp; {studentData.student_number}</li>
              <li style={{ fontWeight: '600' }}><span style={{ fontWeight: '400', width: '200px', display: 'inline-block', fontSize: '22px' }}>Student Name</span> :&nbsp; {studentData.student_name}</li>
              <li style={{ fontWeight: '600' }}><span style={{ fontWeight: '400', width: '200px', display: 'inline-block', fontSize: "22px" }}>Date of issues</span> :&nbsp; {moment(studentData.issuance_date).format('DD-MM-YYYY')}</li>



            </ul>
          </div>
          <div className='row justify-content-center' style={{ width: '100%' }}>
            <div className='col-lg-10'>

              <table style={{ width: "100%" }}>
                <tr>
                  <th className='customcss'>Sr No.</th>
                  <th className='customcss' width="40%">Subject.</th>
                  <th className='customcss'>Marks%</th>
                  <th className='customcss'>Overall%</th>
                  <th className='customcss'>Grade</th>
                  <th className='customcss'>Result</th>
                </tr>
                <tbody>

                  <tr >
                    <td className='operations'> </td>
                    <td className='operations'> Introduction to Hospiltality Operations</td>
                    <td className='operations'></td>
                    <td className='operations dataValue'>75</td>
                    <td className='operations dataValue'>M</td>
                    <td className='operations dataValue'>C</td>
                  </tr>

                  {
                    subjects && subjects.length > 0 ?
                      subjects.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td className='operations2'>{index + 1}</td>
                            <td className='operations2' style={{ fontSize: '15px' }}>{data.name}</td>
                            <td className='operations2 dataValue'>{data.mark}</td>
                            <td className='operations2'></td>
                            <td className='operations2 dataValue'>{data.grade}</td>
                            <td className='operations2'></td>
                          </tr>


                        )

                      })
                      :
                      null

                  }
                  <tr>
                    <td className='operations2'>6</td>
                    <td className='operations2'>Understand Own Rates in Self Development</td>
                    <td className='operations2 dataValue'>85</td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                  </tr>
                  <tr >
                    <td className='operations'> </td>
                    <td className='operations'> Introduction to Kitchen Operations</td>
                    <td className='operations'></td>
                    <td className='operations dataValue'>66</td>
                    <td className='operations dataValue'>P</td>
                    <td className='operations dataValue'>C</td>
                  </tr>
                  <tr>
                    <td className='operations2'>7</td>
                    <td className='operations2'>Food Safety and HACCP in Catring</td>
                    <td className='operations2 dataValue'>70</td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                  </tr>
                  <tr>
                    <td className='operations2'>8</td>
                    <td className='operations2'>Presentation of Food and Assembling of Buffets</td>
                    <td className='operations2 dataValue'>67</td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                  </tr>
                  <tr>
                    <td className='operations2'>9</td>
                    <td className='operations2'>Menu Planning Costing and Catering Controls</td>
                    <td className='operations2 dataValue'>60</td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                  </tr>
                  <tr >
                    <td className='operations'> </td>
                    <td className='operations'>Introduction to Cookery </td>
                    <td className='operations'></td>
                    <td className='operations dataValue'>67</td>
                    <td className='operations dataValue'>P</td>
                    <td className='operations dataValue'>C</td>
                  </tr>
                  <tr>
                    <td className='operations2'>10</td>
                    <td className='operations2'>Use Basic Methods of Cookery</td>
                    <td className='operations2 dataValue'>65</td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                  </tr>
                  <tr>
                    <td className='operations2'>11</td>
                    <td className='operations2'>Organize and Prepare Food</td>
                    <td className='operations2 dataValue'>65</td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                  </tr>
                  <tr>
                    <td className='operations2'>12</td>
                    <td className='operations2'>Prepare Foods to Meet Dietary and Cultural Needs</td>
                    <td className='operations2 dataValue'>70</td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                  </tr>
                  <tr >
                    <td className='operations'> </td>
                    <td className='operations'> Patisserie Major </td>
                    <td className='operations'></td>
                    <td className='operations dataValue '>90</td>
                    <td className='operations dataValue'>D</td>
                    <td className='operations dataValue'>C</td>
                  </tr>
                  <tr>
                    <td className='operations2'>13</td>
                    <td className='operations2'>Prepare Pastry, Cakes and Yeast Goods</td>
                    <td className='operations2 dataValue'>91</td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                  </tr>
                  <tr>
                    <td className='operations2'>14</td>
                    <td className='operations2'>Prepare Hot and Cold Desserts</td>
                    <td className='operations2 dataValue'>80</td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                    <td className='operations2'></td>
                  </tr>




                </tbody>

              </table>

            </div>

          </div>
          <div className='row justify-content-center admistrator' style={{ width: '100%' }}>

            <div className='col-lg-5'>
              <strong>Principle Administrator</strong>

            </div>
            <div className='col-lg-5'>
              <h6>see overall for rules to award of grade</h6>

            </div>
          </div>
          <div className='row justify-content-center Footerdata'>

            <div className='col-lg-5'>
              <img src="/Logo1.png" alt="logo" style={{width:'50%'}} />


            </div>
            <div className='col-lg-7' style={{ textAlign: 'end' }}>
              <span style={{ fontSize: '12px' }}>Block 8</span>&nbsp;<strong className='boderright'></strong>
              <span style={{ fontSize: '12px' }}>First Floor</span>&nbsp;<strong className='boderright'></strong>
              <span style={{ fontSize: '12px' }}>Dubai Acknowledge Park</span>&nbsp;<strong className='boderright'></strong>
              <span style={{ fontSize: '12px' }}>PD Box 500816</span>&nbsp;<strong className='boderright'></strong>
              <span style={{ fontSize: '12px' }}>Dubai UAE</span>

              <div className='contact'>
                Tel +97144578811 <strong className='boderright'></strong> <span style={{ fontSize: '12px' }}>Fax +97144578822</span>
              </div>

              <div className='email'>
                Email : info@iccadubai.ae
              </div>
              <div className='Uae'>
                UAE Toll Free 800 CULINARY <strong className='boderright'></strong> <span style={{ fontSize: '12px' }}>www.iccadubai.ae</span>
              </div>

            </div>

          </div>



        </div>
        <div class="footerTable">
          <div className='footerTable1'>
            <table style={{ width: '100%' }}>

              <tbody>
                <tr>
                  <td width='40%'>
                    <table>
                      <thead>
                        <tr>
                          <th className="footerText" colSpan="3">Mark range to grade</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className='footerTexttd'>100%-90%</td>
                          <td className='D'>D</td>
                          <td>Distinction</td>
                        </tr>
                        <tr>
                          <td className='footerTexttd'>89%-70%</td>
                          <td className='D'>M</td>
                          <td>Merit</td>
                        </tr>
                        <tr>
                          <td className='footerTexttd'>69%-60%</td>
                          <td className='D'>P</td>
                          <td>Pass</td>
                        </tr>
                        <tr>
                          <td className='footerTexttd'>59%-0%</td>
                          <td className='D'>F</td>
                          <td>Fail</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="20%">
                    <table>
                      <thead>
                        <tr>
                          <th ></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td ></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width='40%'>
                    <table>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'start' }} colSpan="2">Result</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>C</td>
                          <td className='D'>Completed</td>

                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td>NYC</td>
                          <td className='D'>Not yet Completed</td>

                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td>EXMP</td>
                          <td className='D'>Example</td>

                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td>C</td>
                          <td className='D'>Completed</td>

                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>

            </table>

          </div>

        </div>



      </div>

    </div>
    </div>
  );
}

export default Student;
