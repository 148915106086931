import React, { Component } from "react";
import './Header.css'
import $ from "jquery";

export default class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        
    }

    logOut = () => {
        console.log("21 abc")
        console.log("remove token", sessionStorage.removeItem('token'))
        sessionStorage.removeItem('token')
        window.location.href = "/admin_login"
    }
    render() {
        return (
            <>
                <div class="afterLogin-page dashboard-wrappers">
                    <div class="dashheaderBx">
                        <div class="headLeftBx">
                            <div class="logoBx">
                                <a href="javascript:;">
                                    <img src="/ICCA-Dubai-logo.png" alt=" " />
                                </a>
                            </div>
                            <div class="hamburger-iconBox">
                                <a class="showMobMenu"><i></i><i></i><i></i></a>
                            </div>
                        </div>
                        <div class="dashHdrRight">
                            <div class="accountBox">
                                <a class="userLink">
                                    {/* <span class="userIconBox"><img src="/userImage.png" alt="" /></span> */}
                                </a>
                                
                                <div class="dropdown show">
                                    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    
                                    </a>

                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item" href="#" > <button type="submit" onClick={this.logOut.bind(this)} class="ac-logoutBtn">Logout </button></a>
                                       
                                    </div>
                               
                                   
                                </div>




                                {/* <ul class="accountMenu"  style={{display: "none"}}>                            
                        <li  ><a href="#">Logout</a></li>
                    </ul> */}

                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
