import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Transcript from '../PdfToHtml/PdfToHtml';
import StudentNumber from '../Home/Home';
import UserForm from '../UserForm'
import RegisterVender from '../RegisterVender/RegisterVender';
import Certificate from '../D.certificate/Certificate'
import VerifiVC from '../../verifiVC/VerifiVC';
import AdminLogin from '../Login/login'
import Dashboard from '../../Pages/dashboard';
import StudentList from '../../Pages/studentList'
import GenerateVC from '../../Pages/GenerateVC'
import NewStudent from '../../Pages/NewStudent'
import VerifyVC from '../../Pages/VerifyVC'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';







export default class Layout extends Component {
    constructor(props){
        super(props)
        this.state ={
            token :'',
            
        }
    }
    componentDidMount(){
        let token = localStorage.getItem('token')      
        this.setState({token:token})
        
    }
    
    
    render() {
        if(this.state.token){
        
        return (
            <div> 
               

                <Router>
                    <Switch>
                        <Route exact path="/student_number" component={StudentNumber} />
                        <Route exact path="/user_form" component={UserForm} />
                        <Route exact path="/verifivc" component={VerifiVC} />
                        <Route exact path="/certificate" component={Certificate} />
                        <Route exact path = '/dashboard' component ={Dashboard} />
                        {/* <Route exact path = '/' component ={Dashboard} /> */}
                        <Route exact path = '/student_list' component ={StudentList} />
                        <Route exact path = '/generateVC' component ={GenerateVC} />
                        <Route exact path = '/newStudent' component ={NewStudent} />
                        <Route exact path = '/verifyVC' component = {VerifyVC} />
                        <Redirect from="/" to="/dashboard" />
                      
                    </Switch>
                </Router>
            </div>

        )
    }  else{
            return (
                <div>                   
                    <Router>
                        <Switch>
                            <Route exact path="/" component={RegisterVender} />
                             <Route exact path="/transcript" component={Transcript} /> 
                            <Route exact path = "/admin_login" component={AdminLogin} />
                            <Redirect from="/" to="/" />
                          
                        </Switch>
                    </Router>
                </div>
            
                    
                
    
    
            )

        }

    }

}