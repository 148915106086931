import React, { Component } from "react";
import moment from "moment";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
const options = {


    marginTop: '0px'

};

export default class CertificatePdf extends Component {
    constructor(props) {

        super(props);
        this.state = {


        };

    }

    render() {


        return (

            <div className='approveInfpop'>
                <button type="button" class="btn btn-primary backbtn1 backbtn" style={{ fontSize: '18px', fontWeight: "600", borderRadius: '25px' }} data-toggle="modal" data-target="#exampleModal2">
                    Download
                </button>

                <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modalOuter1" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                            <div>
                            <div ref={el => (this.componentRef = el)}>

                                <div className="certificateWrapper">
                                    <div className='header_logoPdf'>
                                        <img src='/Logo1.png'></img>

                                    </div>
                                    <div className='header_WrapperPdf'>

                                        <span className='header_textPdf'>Diploma in Hospiltality - Commerical Cookery (Patisserie)</span><br />
                                        <span className='guided_learningPdf'>Guided Learning - 620 hours</span>
                                       


                                    </div>
                                    <div className='certificate_awardPdf'>
                                        <span className='cerificate_text'>
                                            Certificate awarded to
                                        </span><br />
                                        <strong className='ajayani_text'>Ajani Vinayangi Perera Ganegoda Arachchige</strong>

                                    </div>
                                    <div className='attendPdf'>
                                        <span className='W_attend'>Who attended</span><br />
                                        <strong className='I_center'>International Center for Culinary Arts,Dubai</strong><br />
                                        <span className='C_number'>Center No - 806194</span>

                                    </div>
                                    <div className='S_completedPdf'>
                                        <strong className='f_modulesPdf'>And has Sucessfully Completed the Following Modules</strong>

                                    </div>
                                    <div className='list_DataPdf'>
                                        <div className='list_tableLeft'>
                                            <ul>
                                                <li style={{ fontSize: '17px',marginBottom :'15px', display: 'flex', justifyContent: 'space-between' }}><span>Introduction to Hospiltality Operations </span> Pass</li>
                                                <li style={{ fontSize: '17px',marginBottom :'15px', display: 'flex', justifyContent: 'space-between' }}><span>Introduction to Kitchan Operations </span> Pass</li>
                                                <li style={{ fontSize: '17px',marginBottom :'15px', display: 'flex', justifyContent: 'space-between' }}><span>Introduction to Cookery Operations</span> Pass</li>
                                                <li style={{ fontSize: '17px',marginBottom :'15px', display: 'flex', justifyContent: 'space-between' }}><span>Patisserie Major </span> Pass</li>
                                                <li style={{ fontSize: '17px',marginBottom :'15px', display: 'flex', justifyContent: 'space-between' }}><span>Professional Workspace Standards </span> Pass</li>

                                            </ul>
                                        </div>



                                    </div>
                                    <div className='c_signSectionPdf'>
                                        <ul>
                                            <li style={{ fontSize: '18px', display: 'flex', justifyContent: 'space-between' }}><span>Awarded on 19 may 2023</span>ICCA/ICTP/2022/20345</li>


                                        </ul>


                                    </div>
                                    <div className='c_signPdf'>
                                        <small style={{ fontSize: '17px' }}>
                                            Director

                                        </small><br />
                                        <span style={{ fontSize: '14px' }}>International Center for  Culinary Arts FZ LLC</span>

                                    </div>

                                </div>


                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <ReactToPrint className="PrintSection" options={options} documentTitle={"Diploma Certificate"} content={() => this.componentRef}>
                                <PrintContextConsumer >
                                    {({ handlePrint }) => (
                                        <button className="backbtn generatePdf" onClick={handlePrint}>Print this out!</button>
                                    )}

                                </PrintContextConsumer>

                            </ReactToPrint>

                        </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div >


        );
    }
}
